import { Injectable, inject } from "@angular/core";

import {
    IMatomoCommonDimensionIds,
    IMatomoConfiguration,
    IMatomoDimension,
    MatomoStatsUrl
} from "@logex/framework/lg-application";

import { AppConfigurationService } from "../configuration/app-configuration.service";

@Injectable({ providedIn: "root" })
export class AppMatomoConfiguration implements IMatomoConfiguration {
    private _appConfigurationService = inject(AppConfigurationService);
    statUrl: string;
    siteId!: number; // this is a lie, but we need to improve fw typing first
    commonDimensionIds: IMatomoCommonDimensionIds;
    customDimensions = (): IMatomoDimension[] => this._getCustomDimensions();
    autoInit: boolean;
    autoTrack: boolean;

    private _userPosition: string | undefined;

    constructor() {
        this.statUrl = MatomoStatsUrl.MRDM;
        this.commonDimensionIds = {} as any;
        this.autoInit = true;
        this.autoTrack = true;

        this.siteId = this._appConfigurationService.getMatomoConfig().siteId;
    }

    doNotDoMatTracking(): boolean {
        return this._appConfigurationService.getDoNotTrack();
    }

    setUserPosition(position: string | undefined): void {
        this._userPosition = position;
    }

    private _getCustomDimensions(): IMatomoDimension[] {
        const result: IMatomoDimension[] = [];

        if (this._userPosition) {
            result.push({
                id: this._appConfigurationService.getMatomoConfig().positionDimensionId,
                value: this._userPosition
            });
        }

        return result;
    }
}
