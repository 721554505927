import {
    DefaultVisibility,
    XMLDatasetDefinition,
    XMLVariableEnum,
    XMLLayoutDefinition
} from "@fhir-api";

import { FHIRSubject } from "../fhir-middleware";

import { ProjectSection } from "./project-section";
import { ProjectVariable } from "./project-variable";

export class ProjectDataset {
    get name(): string {
        return this._definition.name;
    }

    get label(): string {
        return this._definition.label?.[0].content ?? "";
    }

    get layout(): XMLLayoutDefinition | undefined {
        return this._definition.layout;
    }

    get wordLabel(): string {
        return this.label.toLocaleLowerCase();
    }

    get startLabel(): string {
        const word = this.wordLabel;
        return word[0].toLocaleUpperCase() + word.substring(1);
    }

    get indelible(): boolean {
        return false;
    }

    /**
     * Returns the key variables for this dataset.
     * These variables are used when creating a new subject.
     */
    get keyVariables(): ProjectVariable[] {
        const result: ProjectVariable[] = [];
        if (!this._definition?.keys?.key?.length || !this._variablesLookup) return [];

        const key = this._definition.keys.key[0];

        for (const variable of key.variable) {
            const projectVariable = this.getVariable(variable.name);
            if (projectVariable) result.push(projectVariable);
        }

        return result;
    }

    get parentDataset(): ProjectDataset | null {
        return this._parentDataset;
    }

    get sections(): ProjectSection[] {
        return this._sections;
    }

    get layoutHideAddSubjectInSections(): string[] {
        return [];
    }

    private _parentDataset: ProjectDataset | null = null;
    private _sections: ProjectSection[] = [];
    private _sectionsLookup: Record<string, ProjectSection> = {};
    private _variablesLookup: Record<string, ProjectVariable> | null = null;

    constructor(private _definition: XMLDatasetDefinition) {
        this._setSections();
        this._setVariables();
    }

    getVariable(name: string): ProjectVariable | undefined {
        return this._variablesLookup?.[name];
    }

    getSection(name: string): ProjectSection | null {
        return this._sectionsLookup[name] ?? null;
    }

    getPreviousSection(
        section: ProjectSection,
        subject?: FHIRSubject | null
    ): ProjectSection | null {
        const index = this._sections.indexOf(section);
        if (index === -1) return null;

        const prevSection = this._sections[index - 1] ?? null;

        if (prevSection && subject && !prevSection.getIsVisible(subject)) {
            return this.getPreviousSection(prevSection, subject);
        }

        return prevSection;
    }

    getNextSection(section: ProjectSection, subject?: FHIRSubject | null): ProjectSection | null {
        const index = this._sections.indexOf(section);
        if (index === -1) return null;

        const nextSection = this._sections[index + 1] ?? null;

        if (nextSection && subject && !nextSection.getIsVisible(subject)) {
            return this.getNextSection(nextSection, subject);
        }

        return nextSection;
    }

    getLastSection(subject: FHIRSubject): ProjectSection | null {
        const lastSection = this._sections.at(-1);
        if (lastSection !== undefined && !lastSection.getIsVisible(subject)) {
            return this.getPreviousSection(lastSection, subject);
        }
        return lastSection ?? null;
    }

    getParentSection(): ProjectSection | null {
        return (
            this.parentDataset?.sections.find(section =>
                section.variables.some(
                    variable =>
                        variable.type === XMLVariableEnum.Dataset && variable.name === this.name
                )
            ) ?? null
        );
    }

    createNewSection({ visibilities }: { visibilities?: DefaultVisibility[] }): ProjectSection {
        const keyVariables = this.keyVariables;
        const section = new ProjectSection(
            {
                name: "$new",
                variable: keyVariables.map(variable => ({
                    ...variable.definition
                }))
            },
            {
                visibilities
            }
        );
        return section;
    }

    setParentDataset(dataset: ProjectDataset): void {
        this._parentDataset = dataset;
    }

    private _setSections(): void {
        for (const sectionData of this._definition.section ?? []) {
            const dataentrySection = new ProjectSection(sectionData);
            this._sections.push(dataentrySection);
            this._sectionsLookup[sectionData.name] = dataentrySection;
        }
    }

    private _setVariables(): void {
        this._variablesLookup = {};
        for (const section of this._sections) {
            const variables = section.variables;
            for (const variable of variables) {
                this._variablesLookup[variable.name] = variable;
            }
        }
    }
}
