import { XMLControlEnum, XMLProjectData, XMLVariableEnum } from "@fhir-api";
import { AppProjectsService } from "../../services/app-projects.service";

import { ProjectDataset } from "./project-dataset";
import { ProjectVariable } from "./project-variable";
import { ProjectSection } from "./project-section";

interface ProjectConstructorOptions {
    organizationUri?: string;
}

export class Project {
    get name(): string {
        return this._definition.name ?? "";
    }

    get label(): string {
        return this._definition.label?.[0]?.content ?? "";
    }

    get description(): string {
        return this._definition.description?.[0]?.content ?? "";
    }

    get defaultLanguage(): string | null {
        return this._definition["default-language"] ?? null;
    }

    get rootDataset(): ProjectDataset | null {
        return this._rootDataset;
    }

    get rootDatasetName(): string {
        return this._rootDataset?.name ?? "";
    }

    get datasets(): ProjectDataset[] {
        return this._datasets;
    }

    get url(): string {
        return this._url;
    }

    get defaultControl(): XMLControlEnum | "" {
        return this._definition.controls?.default ?? "";
    }

    get skipEmptyCreationDialog(): boolean {
        return false;
    }

    get hideSubjectCountInSubjectList(): boolean {
        return false;
    }

    private _url = "";
    private _rootDataset: ProjectDataset | null = null;
    private _datasets: ProjectDataset[] = [];
    private _datasetsLookup: Record<string, ProjectDataset> = {};

    private _variables: ProjectVariable[] = [];
    private _variablesLookup: Record<string, ProjectVariable> = {};

    private _product: string | null = null;

    constructor(
        private _projectsService: AppProjectsService,
        private _definition: XMLProjectData,
        { organizationUri }: ProjectConstructorOptions = {}
    ) {
        this._connectDatasets();
        this._setVariables();
        this._setProduct();

        if (organizationUri !== undefined) {
            this._url = `/registry/${organizationUri}/${this.name}`;
        }
    }

    getDataset(name: string): ProjectDataset | null {
        return this._datasetsLookup[name] ?? null;
    }

    getDatasetSections(name: string): ProjectSection[] {
        const dataset = this.getDataset(name);
        return dataset?.sections ?? [];
    }

    getVariable(name: string): ProjectVariable | null {
        return this._variablesLookup[name] ?? null;
    }

    getInterestingVariable(): ProjectVariable | null {
        return this._variables.find(variable => variable.interesting) ?? null;
    }

    getProduct(): string | null {
        return this._product;
    }

    getIconResource(name: string): {
        disabled?: boolean;
        src: string;
        tooltip: string;
    } | null {
        return null;
    }

    private _connectDatasets(): void {
        const parents: Record<string, string> = {};

        (this._definition.dataset ?? []).forEach(dataset => {
            (dataset.section ?? []).forEach(section => {
                section.variable.forEach(variable => {
                    if (variable.type === XMLVariableEnum.Dataset) {
                        parents[variable.name] = dataset.name;
                    }
                });
            });
        });

        for (const datasetData of this._definition.dataset ?? []) {
            const dataset = new ProjectDataset(datasetData);
            this._datasets.push(dataset);
            this._datasetsLookup[datasetData.name] = dataset;
        }

        this._datasets.forEach(dataset => {
            const parent = parents[dataset.name];

            if (parent) {
                const parentDataset = this._datasetsLookup[parent];

                if (parentDataset) {
                    dataset.setParentDataset(parentDataset);
                }
            } else if (this._rootDataset === null) {
                this._rootDataset = dataset;
            } else {
                throw new Error(
                    `Multiple root datasets: ${this._rootDataset.name}, ${dataset.name}`
                );
            }
        });
    }

    private _setVariables(): void {
        for (const dataset of this._datasets) {
            this._variables.push(...dataset.sections.map(section => section.variables).flat());
            this._variablesLookup = this._variables.reduce<Record<string, ProjectVariable>>(
                (lookup, variable) => {
                    lookup[variable.name] = variable;
                    return lookup;
                },
                {}
            );
        }
    }

    private _setProduct(): void {
        const project = this._projectsService.getProjectByUri(this.name);
        if (project?.properties.product === undefined) return;

        this._product = project.properties.product.replace("Product.", "");
    }
}
