import { DatePipe } from "@angular/common";
import decodeHtmlEntities from "@app/util/html-util";
import {
    SubjectVariableValidationData,
    XMLControlEnum,
    XMLControlTypeDefinitionEnum,
    XMLOptionVisibilityEnum,
    XMLOptionsetDefinition,
    XMLVariableDefinition,
    XMLVariableEnum,
    XMLVisibilityEnum
} from "@fhir-api";

import { FHIRSubjectVariable } from "../fhir-middleware";
import { IconResource, OptionImageDefinition } from "../fhir-middleware/fhir-attribute";

export const enum DataEntryUIControlType {
    Radio = "radio",
    ImageRadio = "image-radio",
    Checkbox = "checkbox",
    Select = "select",
    Autocomplete = "autocomplete",
    Textarea = "textarea",
    Text = "text",
    Date = "date",
    DateTime = "datetime",
    Integer = "integer",
    Float = "float",
    Dataset = "dataset",
    Paragraph = "paragraph",
    ExternalDatasetCount = "external-dataset-count",
    ExternalDataset = "external-dataset",
    CreateButton = "create-button"
}

export interface VariableOption {
    value: string;
    label: string;
    description: string;
    disabled: boolean;
    image?: OptionImageDefinition;
}

interface VariableConstructorOptions {
    unselectableOptions?: string[];
}

export class ProjectVariable {
    get definition(): XMLVariableDefinition {
        return this._definition;
    }

    get name(): string {
        return this._definition.name;
    }

    get label(): string {
        return this._definition.label[0]?.content ?? "";
    }

    get length(): number {
        return Number(this._definition.length ?? 0);
    }

    get type(): XMLVariableEnum {
        return this._definition.type;
    }

    get category(): string {
        return this._definition.category ?? "";
    }

    get categoryLabel(): string {
        return this._definition["category-label"] ?? "";
    }

    get control(): XMLControlEnum | "" {
        return this._definition.control ?? "";
    }

    get controlVariant(): XMLControlTypeDefinitionEnum | "" {
        return this._definition["control-type"] ?? "";
    }

    get forceOptionsetValue(): boolean {
        if (!("force-optionset-value" in this._definition)) return true;
        return this._definition["force-optionset-value"] ?? false;
    }

    get optionset(): XMLOptionsetDefinition | undefined {
        return this._definition.optionset;
    }

    get hasOptions(): boolean {
        return (
            this._definition.optionset?.option !== undefined &&
            this._definition.optionset?.option?.length > 0
        );
    }

    get required(): boolean {
        return this._definition.required ?? false;
    }

    get format(): string | null {
        return this._definition.format ?? null;
    }

    get interesting(): boolean {
        return this._definition.interesting ?? false;
    }

    get help(): string {
        return this._definition.help?.[0]?.content ?? "";
    }

    get isExternalOptionset(): boolean {
        return this._definition.optionset?.external !== undefined;
    }

    get isBarcodeScannerInput(): boolean {
        return false;
    }

    get isUnskippable(): boolean {
        return false;
    }

    get resourceIcon(): IconResource | undefined {
        return undefined;
    }

    private _options: VariableOption[] = [];
    private _unselectableOptions?: string[];

    private _datePipe: DatePipe = new DatePipe("nl-NL");

    constructor(
        protected readonly _definition: XMLVariableDefinition,
        { unselectableOptions }: VariableConstructorOptions = {}
    ) {
        this._unselectableOptions = unselectableOptions;

        this._setOptions();
    }

    getUIControlType(defaultControl: XMLControlEnum): DataEntryUIControlType {
        let control = this.control;
        let kind: XMLVariableEnum | XMLControlEnum | "" = this.type ?? "";

        if (this.optionset && !this.optionset.external && this.optionset.option?.length) {
            if (this.forceOptionsetValue) {
                kind = defaultControl;
            }
        }

        if (control) {
            switch (control) {
                case XMLControlEnum.Radio:
                case XMLControlEnum.Select:
                case XMLControlEnum.Autocomplete:
                    if (this.forceOptionsetValue) {
                        kind = control;
                    }
                    break;
                case XMLControlEnum.Textarea:
                    kind = XMLControlEnum.Textarea;
                    break;
            }
        } else if (this.forceOptionsetValue && this.optionset) {
            const active = this._options.length ?? this.optionset?.option?.length ?? 0;

            if (active < 8) {
                control = XMLControlEnum.Radio;
            } else {
                control = defaultControl;
            }

            kind = control;
        }

        switch (kind) {
            case XMLControlEnum.Radio:
                if (this._options.length === 1) {
                    return DataEntryUIControlType.Checkbox;
                } else if (this._options.find(option => option.image)) {
                    return DataEntryUIControlType.ImageRadio;
                }

                return DataEntryUIControlType.Radio;

            case XMLControlEnum.Select:
                return DataEntryUIControlType.Select;

            case XMLControlEnum.Autocomplete:
                return DataEntryUIControlType.Autocomplete;

            case XMLControlEnum.Textarea:
                return DataEntryUIControlType.Textarea;

            case XMLVariableEnum.Date:
                return DataEntryUIControlType.Date;

            case XMLVariableEnum.DateTime:
                return DataEntryUIControlType.DateTime;

            case XMLVariableEnum.Integer:
                return DataEntryUIControlType.Integer;

            case XMLVariableEnum.Decimal:
                return DataEntryUIControlType.Float;

            case XMLVariableEnum.Dataset:
                return DataEntryUIControlType.Dataset;

            case XMLVariableEnum.Paragraph:
                return DataEntryUIControlType.Paragraph;
        }

        return DataEntryUIControlType.Text;
    }

    getValue(subjectVariable?: FHIRSubjectVariable | null): string {
        if (!subjectVariable) return "";
        return subjectVariable.value || "";
    }

    getValueLabel(subjectVariable?: FHIRSubjectVariable | null): string {
        if (this.hasOptions) {
            const option = this._options.find(o => o.value === this.getValue(subjectVariable));
            return option?.label ?? "";
        }
        return this.getFormattedValue(this.getValue(subjectVariable));
    }

    getIsValid(subjectVariable?: FHIRSubjectVariable | null): boolean {
        if (!subjectVariable) return true;
        return subjectVariable.valid;
    }

    getIsLocked(subjectVariable?: FHIRSubjectVariable | null): boolean {
        if (this._definition.visibility === XMLVisibilityEnum.Readonly) return true;
        return subjectVariable?.locked ?? false;
    }

    getIsVisible(subjectVariable?: FHIRSubjectVariable | null): boolean {
        if (this._definition.visibility === XMLVisibilityEnum.Hidden) return false;
        if (!subjectVariable) return true;
        return subjectVariable.visibility !== "hidden" && subjectVariable.visible;
    }

    getIsCardinalityReached(subjectVariable?: FHIRSubjectVariable | null): boolean {
        if (!subjectVariable) return false;
        return subjectVariable.cardinalityReached;
    }

    getIsWriteable(subjectVariable?: FHIRSubjectVariable | null): boolean {
        if (!subjectVariable) return true;

        const canCreate =
            this._definition.type !== XMLVariableEnum.Dataset || subjectVariable.canCreate;

        return (
            !this.getIsLocked(subjectVariable) &&
            subjectVariable.visible &&
            subjectVariable.visibility !== "readonly" &&
            subjectVariable.visibility !== "hidden" &&
            canCreate
        );
    }

    getCanCreate(subjectVariable?: FHIRSubjectVariable | null): boolean {
        if (!subjectVariable) return false;
        return subjectVariable.canCreate;
    }

    getValidations(
        subjectVariable?: FHIRSubjectVariable | null
    ): SubjectVariableValidationData[] | null {
        if (!subjectVariable) return null;
        return subjectVariable.validations?.length ? subjectVariable.validations : null;
    }

    getHiddenOptions(subjectVariable?: FHIRSubjectVariable | null): Set<string> | null {
        if (!subjectVariable) return null;

        const hiddenOptions = subjectVariable.hiddenOptions;
        const defaultHiddenOptions =
            this._definition.optionset?.["option-visibility"] === XMLOptionVisibilityEnum.Hidden
                ? (this._unselectableOptions ?? [])
                : [];

        const hidden = [...hiddenOptions, ...defaultHiddenOptions];
        if (hidden.length === 0) return null;

        return new Set(hidden);
    }

    getOptions(subjectVariable?: FHIRSubjectVariable | null): VariableOption[] {
        if (!subjectVariable) return this._options;

        const hidden = this.getHiddenOptions(subjectVariable);
        if (hidden == null) return this._options;

        return this._options.filter(option => !hidden.has(option.value));
    }

    getOptionImage(subjectVariable?: FHIRSubjectVariable | null): OptionImageDefinition | null {
        if (!subjectVariable) return null;

        const options = this.getOptions(subjectVariable);
        if (!options) return null;

        const option = options.find(o => o.value === subjectVariable.value);
        return option?.image ?? null;
    }

    getFormattedValue(value: string): string {
        if (this.type === XMLVariableEnum.Date) {
            return this._datePipe.transform(value, "dd-MM-yyyy") ?? "";
        }
        if (this.type === XMLVariableEnum.DateTime) {
            return this._datePipe.transform(value, "dd-MM-yyyy HH:mm") ?? "";
        }
        return value;
    }

    private _setOptions(): void {
        if (this.optionset && !this.optionset.external && this.optionset.option?.length) {
            this._options = this.optionset.option.map(option => {
                const variableOption: VariableOption = {
                    value: option.value,
                    label: decodeHtmlEntities(option.label[0]?.content ?? null) ?? "",
                    description: option.description?.[0]?.content ?? "",
                    disabled:
                        this._definition.optionset?.["option-visibility"] ===
                            XMLOptionVisibilityEnum.Disabled &&
                        (this._unselectableOptions ?? []).includes(option.value)
                };

                return variableOption;
            });
        }
    }
}
