import { inject, Injectable } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

import { IProduct } from "@logex/framework/lg-layout";
import { LgTranslateService } from "@logex/framework/lg-localization";

import { MrdmAuthApiService } from "./mrdm-auth-api.service";
import { getMrdmProductUrl, MRDM_PRODUCTS } from "./mrdm-products";
import { AuthorizationExtendedPermission, AuthorizationOrganization } from "./mrdm-auth-api.types";

@Injectable({
    providedIn: "root"
})
export class MrdmAuthService {
    private _authorizationApiService = inject(MrdmAuthApiService);
    private _translateService = inject(LgTranslateService);

    readonly user$ = this._authorizationApiService.getProfile().pipe(shareReplay(1));
    readonly userPermissions$ = this._searchPermissions$();
    readonly userOrganizations$ = this._getFilteredUserOrganizations$();

    readonly isInternalUser$ = this.user$.pipe(map(profile => profile.isInternal));

    getMrdmProducts$(
        env: string,
        tenant: string,
        projectProduct: string | null
    ): Observable<IProduct[]> {
        return this._getAllowedAppInstances().pipe(
            map(allowedAppInstances =>
                MRDM_PRODUCTS.filter(product =>
                    product.appInstances.some(appInstance =>
                        allowedAppInstances.includes(appInstance)
                    )
                ).map(product => ({
                    id: product.id,
                    name: product.name ?? this._translateService.translate(product.nameLc ?? ""),
                    url: getMrdmProductUrl(env, product, tenant, projectProduct),
                    shortDescription: product.shortDescription,
                    iconClass: product.iconClass
                }))
            )
        );
    }

    private _searchPermissions$(): Observable<AuthorizationExtendedPermission[]> {
        const applicationInstances = MRDM_PRODUCTS.map(product => product.appInstances).flat();
        return this._authorizationApiService
            .searchPermissions({ applicationInstances })
            .pipe(shareReplay(1));
    }

    private _getFilteredOrganizationCicCodes$(): Observable<number[]> {
        return this.userPermissions$.pipe(
            map(permissions => {
                const filteredCicCodes: number[] = permissions
                    .filter(perms =>
                        perms.applicationInstance.includes("ApplicationInstance.Survey.NL")
                    )
                    .map(item => item.cicCode)
                    .filter(cicCode => cicCode !== null) as number[];

                return [...new Set(filteredCicCodes)];
            })
        );
    }

    private _getFilteredUserOrganizations$(): Observable<AuthorizationOrganization[]> {
        return combineLatest([this.user$, this._getFilteredOrganizationCicCodes$()]).pipe(
            map(([user, filteredCicCodes]) => {
                const organizations = user.organizations;
                return organizations.filter(
                    organization =>
                        organization.cicCode !== null &&
                        filteredCicCodes.includes(organization.cicCode)
                );
            })
        );
    }

    private _getAllowedAppInstances(): Observable<string[]> {
        return this.userPermissions$.pipe(
            map(permissions => {
                const appInstances = permissions.map(permission => permission.applicationInstance);
                return [...new Set(appInstances)];
            })
        );
    }
}
