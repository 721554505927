import { FormConfigurationEntityGroup, ScmEntityGroup } from "@fhir-api";
import { FhirEntityList } from "./fhir-entity-list";

export class FhirEntityListGroup {
    private _entityLists: FhirEntityList[] = [];

    get scm(): ScmEntityGroup {
        return this._scm;
    }

    get formConfiguration(): FormConfigurationEntityGroup | undefined {
        return this._formConfiguration;
    }

    get entityLists(): FhirEntityList[] {
        return this._entityLists;
    }

    constructor(
        private _scm: ScmEntityGroup,
        private _formConfiguration?: FormConfigurationEntityGroup
    ) {
        this._entityLists = _scm?.childContract?.childContracts.map(
            (entityList, index) =>
                new FhirEntityList(
                    entityList,
                    {
                        parentEntityListGroup: this
                    },
                    this._formConfiguration?.childContract?.[index]
                )
        );
        this._entityLists.forEach(entityList => {
            entityList.initializeSections(
                this.entityLists.flatMap(entityList => entityList.entity)
            );
        });
    }
}
