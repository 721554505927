import { FormConfigurationVisibilityEnum, SubjectVariableData } from "@fhir-api";
import { FHIRSubject } from "./subject";
import { ValidationError } from "@logex/expression-validator";
import { FhirEntity } from "./entity";
import { FhirAttribute } from "./attribute";
import { BehaviorSubject } from "rxjs";

interface FHIRSubjectVariableConstructorOptions {
    parentSubject: FHIRSubject;
}
export class FHIRSubjectVariable {
    static create(
        data: SubjectVariableData,
        attribute: FhirAttribute,
        options: FHIRSubjectVariableConstructorOptions
    ): FHIRSubjectVariable {
        return new FHIRSubjectVariable(data, attribute, options);
    }

    get parentSubject(): FHIRSubject {
        return this._parentSubject;
    }

    get parentEntity(): FhirEntity {
        return this._parentSubject.entity;
    }

    get name(): string {
        return this._name;
    }

    set value(value: string | null) {
        this._value = value;
    }

    get type(): string | undefined {
        return this._type;
    }

    get value(): string {
        return this._value == null ? this.attribute.defaultValue : this._value;
    }

    get attribute(): FhirAttribute {
        return this._attribute;
    }

    get visible(): boolean {
        if (this._attribute.visibility === FormConfigurationVisibilityEnum.Hidden) return false;
        return this._parentSubject.validations?.availability[this.name] ?? true;
    }

    get hiddenOptions(): string[] {
        const fieldsAvailability =
            this._parentSubject.validations?.fieldsOptionsAvailability[this.name];
        if (fieldsAvailability) {
            return Object.entries(fieldsAvailability)
                .filter(([key, value]) => value === false)
                .map(([key, value]) => key);
        }
        return [];
    }

    get valid(): boolean {
        return this.validations?.length === 0 ?? true;
    }

    get validations(): ValidationError[] {
        return (
            this.parentSubject?.validations?.errors.filter(error =>
                error.attributes.includes(this.name)
            ) ?? []
        );
    }

    private _parentSubject: FHIRSubject;
    private _value: string | null = null;
    private _type?: string;
    private _name!: string;

    constructor(
        private _data: SubjectVariableData,
        private _attribute: FhirAttribute,
        { parentSubject }: FHIRSubjectVariableConstructorOptions
    ) {
        this._parentSubject = parentSubject;
        this._name = _data.name;
        this._type = _data.type;
        this.value = _data.value || null;
    }
}
