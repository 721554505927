import {
    Scm,
    ScmAttributeTypeEnum,
    ScmAttachedExtensionTypeEnum,
    ScmContractStatus,
    ScmIdType,
    GetScmResponseData
} from "./get-scm-response-data";

export const DHBA_2023_SCM: Scm = {
    version: "2.0.0",
    draftName: null,
    draftBase: null,
    intendedProject: null,
    childContracts: [
        {
            preferredOrder: 1,
            required: true,
            childContract: {
                version: "1.0.0",
                draftName: null,
                draftBase: null,
                childContracts: [
                    {
                        rootEntityList: null,
                        preferredOrder: 1,
                        required: true,
                        childContract: {
                            version: "1.0.0",
                            draftName: null,
                            draftBase: null,
                            childContract: {
                                type: "entity",
                                childContract: {
                                    version: "1.0.0",
                                    draftName: null,
                                    draftBase: null,
                                    attributes: [
                                        {
                                            regexValidation: null,
                                            rangeExtension: null,
                                            dataType: ScmAttributeTypeEnum.Integer,
                                            name: "id",
                                            description: "Zorginstelling",
                                            interpretAs: null,
                                            directPii: false,
                                            required: true,
                                            includeWhen: null,
                                            preferredOrder: 1,
                                            encryptedValues: false,
                                            qualityRules: []
                                        },
                                        {
                                            stringExtension: {
                                                maxLength: 15
                                            },
                                            regexValidation: null,
                                            dataType: ScmAttributeTypeEnum.String,
                                            name: "upn",
                                            description: "Uniek Patiëntnummer",
                                            interpretAs: null,
                                            directPii: true,
                                            required: true,
                                            includeWhen: null,
                                            preferredOrder: 2,
                                            encryptedValues: false,
                                            qualityRules: []
                                        },
                                        {
                                            stringExtension: null,
                                            regexValidation: null,
                                            dataType: ScmAttributeTypeEnum.Enum,
                                            name: "land",
                                            description: "Land Burger Service Nummer",
                                            interpretAs: null,
                                            directPii: false,
                                            required: true,
                                            includeWhen: null,
                                            preferredOrder: 3,
                                            encryptedValues: false,
                                            qualityRules: [],
                                            referenceExtension: {
                                                enumName: "land-short"
                                            }
                                        },
                                        {
                                            stringExtension: {
                                                maxLength: 15
                                            },
                                            regexValidation: null,
                                            dataType: ScmAttributeTypeEnum.String,
                                            name: "idcode",
                                            description: "Burger Service Nummer",
                                            interpretAs: null,
                                            directPii: false,
                                            required: true,
                                            includeWhen: null,
                                            preferredOrder: 4,
                                            encryptedValues: false,
                                            qualityRules: []
                                        },
                                        {
                                            stringExtension: {
                                                maxLength: 10
                                            },
                                            regexValidation: null,
                                            dataType: ScmAttributeTypeEnum.String,
                                            name: "tussen",
                                            description: "Voorvoegsels",
                                            interpretAs: null,
                                            directPii: false,
                                            required: false,
                                            includeWhen: null,
                                            preferredOrder: 5,
                                            encryptedValues: false,
                                            qualityRules: []
                                        },
                                        {
                                            stringExtension: {
                                                maxLength: 40
                                            },
                                            regexValidation: null,
                                            dataType: ScmAttributeTypeEnum.String,
                                            name: "naam",
                                            description: "Geslachtsnaam",
                                            interpretAs: null,
                                            directPii: false,
                                            required: true,
                                            includeWhen: null,
                                            preferredOrder: 6,
                                            encryptedValues: false,
                                            qualityRules: []
                                        },
                                        {
                                            rangeExtension: null,
                                            dataType: ScmAttributeTypeEnum.Date,
                                            name: "gebdat",
                                            description: "Geboortedatum",
                                            interpretAs: null,
                                            directPii: false,
                                            required: true,
                                            includeWhen: null,
                                            preferredOrder: 7,
                                            encryptedValues: false,
                                            qualityRules: []
                                        },
                                        {
                                            regexValidation: null,
                                            rangeExtension: null,
                                            dataType: ScmAttributeTypeEnum.Enum,
                                            name: "geslacht",
                                            description: "Geslacht",
                                            interpretAs: null,
                                            directPii: false,
                                            required: true,
                                            includeWhen: null,
                                            preferredOrder: 8,
                                            encryptedValues: false,
                                            qualityRules: [],
                                            referenceExtension: {
                                                enumName: "geslacht-full"
                                            }
                                        },
                                        {
                                            regexValidation: null,
                                            rangeExtension: null,
                                            dataType: ScmAttributeTypeEnum.Enum,
                                            name: "land-woon",
                                            description: "Land (Woonadres)",
                                            interpretAs: null,
                                            directPii: false,
                                            required: true,
                                            includeWhen: null,
                                            preferredOrder: 9,
                                            encryptedValues: false,
                                            qualityRules: [],
                                            referenceExtension: {
                                                enumName: "land-short"
                                            }
                                        },
                                        {
                                            stringExtension: {
                                                maxLength: 7
                                            },
                                            regexValidation: null,
                                            dataType: ScmAttributeTypeEnum.String,
                                            name: "pcode",
                                            description: "Postcode",
                                            interpretAs: null,
                                            directPii: false,
                                            required: true,
                                            includeWhen: null,
                                            preferredOrder: 10,
                                            encryptedValues: false,
                                            qualityRules: []
                                        },
                                        {
                                            rangeExtension: null,
                                            dataType: ScmAttributeTypeEnum.Date,
                                            name: "datovl",
                                            description: "Datum overlijden (indien van toepassing)",
                                            interpretAs: null,
                                            directPii: false,
                                            required: false,
                                            includeWhen: null,
                                            preferredOrder: 11,
                                            encryptedValues: false,
                                            qualityRules: []
                                        }
                                    ],
                                    contractValidations: [],
                                    temporaries: [],
                                    attachedExtension: [
                                        {
                                            enumOptions: [
                                                ["nl", "Nederland"],
                                                ["de", "Duitsland"],
                                                ["be", "België"],
                                                ["se", "Zweden"],
                                                ["unknown", "Anders/Onbekend"]
                                            ],
                                            version: "1.0.0",
                                            attachmentType: ScmAttachedExtensionTypeEnum.Enum,
                                            tableName: "land-short",
                                            description: null,
                                            status: ScmContractStatus.Published,
                                            idType: ScmIdType.Integer,
                                            sortBy: null
                                        },
                                        {
                                            enumOptions: [
                                                ["1", "Man"],
                                                ["2", "Vrouw"],
                                                ["7", "Ongedifferentieerd"],
                                                ["9", "Onbekend"]
                                            ],
                                            version: "1.0.0",
                                            attachmentType: ScmAttachedExtensionTypeEnum.Enum,
                                            tableName: "geslacht-full",
                                            description: null,
                                            status: ScmContractStatus.Published,
                                            idType: ScmIdType.Integer,
                                            sortBy: null
                                        }
                                    ],
                                    uuid: "066bcb21-ff2e-7b75-8000-450055e0da8d",
                                    type: "entity",
                                    businessId: "d3dd7fae-a0be-4fd2-8852-33b526c0922a",
                                    name: "DHBA Patient test_entity",
                                    description: "",
                                    author: "Victoria.Gardukevich@logex.com",
                                    maintainer: "Victoria Gardukevich",
                                    status: ScmContractStatus.Published,
                                    storageType: "lakehouse",
                                    requiredBindings: null,
                                    businessAlias: "patient"
                                },
                                primaryKey: ""
                            },
                            validations: [],
                            uuid: "066bcb22-00a1-721e-8000-c555dc5c61bc",
                            type: "entitylist",
                            businessId: "aa93995b-2a64-40c6-9d21-4f8b94819215",
                            name: "DHBA Patient List test_entitylist",
                            description: "",
                            author: "Victoria.Gardukevich@logex.com",
                            maintainer: "Victoria Gardukevich",
                            status: ScmContractStatus.Published
                        },
                        storageType: "lakehouse",
                        tableName: null,
                        instancePointer: null,
                        primaryKey: null,
                        sortField: null,
                        sortBy: null,
                        dataVersion: null,
                        referenceDateTime: null
                    }
                ],
                keyedRelations: [],
                validations: [],
                uuid: "066bcb22-0232-7f93-8000-8d4ecd7fd669",
                type: "entitylistgroup",
                businessId: "32e40e91-f466-430f-bf4a-03010bb76829",
                name: "DHBA Patient List Group test",
                description: "",
                author: "Victoria.Gardukevich@logex.com",
                maintainer: "Victoria Gardukevich",
                status: ScmContractStatus.Published
            },
            thresholdOverrides: []
        }
    ],
    uuid: "821b76f6-6f62-4e34-873c-eb1ff9e8381a",
    type: "dataset",
    businessId: "81dccd67-90f3-4ac2-828a-5d2df3984bba",
    name: "DHBA Patient Registry test",
    description: "Simple DHBA Contract for DE Pilot",
    author: "Victoria.Gardukevich@logex.com",
    maintainer: "Victoria Gardukevich",
    status: ScmContractStatus.Published
};

export const GET_SCM_RESPONSE_TESTDATA: GetScmResponseData = {
    contract: DHBA_2023_SCM
};
