import { Component, OnInit, inject, DestroyRef } from "@angular/core";
import { registerLocaleData } from "@angular/common";
import localeNl from "@angular/common/locales/nl";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

import { Auth0AuthorizationService } from "@authorization";

import { AppNavigationManagerService } from "./services/state/app-navigation-manager.service";
import { AppProjectsService } from "./services/app-projects.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
    private _appNavigationManagerService = inject(AppNavigationManagerService);
    private _appProjectsService = inject(AppProjectsService);
    private _auth0Authorization = inject(Auth0AuthorizationService);
    private _destroyRef = inject(DestroyRef);

    _errorMessage: string | null = null;

    ngOnInit(): void {
        this._appNavigationManagerService.init();
        this._appProjectsService.init();

        registerLocaleData(localeNl);

        this._auth0Authorization.authorizationError$
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(error => {
                this._errorMessage = error.message;
                console.error(error);
                setTimeout(() => this._logout(), 30000);
            });

        this._auth0Authorization.newProfile$.subscribe(() => window.location.reload());
    }

    _logout($event?: Event): void {
        if ($event) {
            $event.stopPropagation();
            $event.preventDefault();
        }
        this._auth0Authorization.logout();
    }
}
