import { FHIRSubject } from "../fhir-middleware";
import { ProjectVariable } from "./project-variable";

export class ProjectSectionCategory {
    private _variables: ProjectVariable[] = [];

    get variables(): ProjectVariable[] {
        return this._variables;
    }

    get label(): string {
        return this._label.trim();
    }

    constructor(
        public name: string,
        private _label: string
    ) {}

    addVariable(variable: ProjectVariable): void {
        this._variables.push(variable);
    }

    getIsVisible(subject: FHIRSubject | null): boolean {
        return this._variables.some(variable => {
            const subjectVariable = subject?.variables.find(sv => sv.name === variable.name);
            return variable.getIsVisible(subjectVariable);
        });
    }
}
