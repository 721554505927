let decodeArea: HTMLTextAreaElement | null = null;

export default function decodeHtmlEntities(input: null | string): null | string {
    if (input === null) return input;
    if (input.indexOf("&") === -1) return input;

    if (decodeArea === null) {
        decodeArea = document.createElement("textarea");
    }

    decodeArea.innerHTML = input;
    const result = decodeArea.childNodes.length === 0 ? "" : decodeArea.childNodes[0].nodeValue;
    decodeArea.textContent = "";

    return result;
}
