import { DatasetData } from "@fhir-api";

import { FHIRSubject } from "./fhir-subject";
import { ProjectVariable } from "../metadata";
import { FhirAttribute } from "./fhir-attribute";

interface FHIRSubjectDatasetConstructorOptions {
    parentSubject?: FHIRSubject | string; // string is the project url
    parentDataset?: FHIRSubjectDataset;
}

export class FHIRSubjectDataset {
    static create(
        data: DatasetData,
        options: FHIRSubjectDatasetConstructorOptions = {}
    ): FHIRSubjectDataset {
        return new FHIRSubjectDataset(data, options);
    }

    static createEmpty(name: string): FHIRSubjectDataset {
        const data: DatasetData = {
            name,
            subject: []
        };

        return FHIRSubjectDataset.create(data);
    }

    get url(): string {
        return this._url;
    }

    get apiPath(): string {
        return this._apiPath;
    }

    get name(): string {
        return this._data.name;
    }

    get subjects(): FHIRSubject[] {
        return this._subjects;
    }

    get parentDataset(): FHIRSubjectDataset | null {
        return this._parentDataset;
    }

    get parentSubject(): FHIRSubject | null {
        return this._parentSubject;
    }

    private _url = "";
    private _apiPath = "";
    private _parentDataset: FHIRSubjectDataset | null;
    private _parentSubject: FHIRSubject | null;
    private _subjects: FHIRSubject[] = [];
    private _subjectsLookup: Record<string, FHIRSubject> = {};

    constructor(
        private _data: DatasetData,
        { parentSubject, parentDataset }: FHIRSubjectDatasetConstructorOptions = {}
    ) {
        if (typeof parentSubject === "string") {
            this._parentSubject = null;
            this._url = parentSubject;
        } else if (parentSubject) {
            this._parentSubject = parentSubject;
            this._url = `${this._parentSubject.url}/${this._data.name}`;
        } else {
            this._parentSubject = null;
        }

        this._parentDataset = parentDataset ?? null;

        if (this._parentDataset) {
            this._apiPath = `${this._parentDataset.apiPath}.${this._data.name}`;
        } else {
            this._apiPath = this._data.name;
        }

        this._processSubjects();
    }

    createNewSubject({
        keyVariables,
        defaultValues
    }: {
        keyVariables?: FhirAttribute[];
        defaultValues?: Record<string, string | null>;
    } = {}): FHIRSubject {
        const subject = FHIRSubject.createEmpty({
            parentDataset: this,
            keyVariables,
            defaultValues
        });

        return subject;
    }

    getSubject(name: string): FHIRSubject | null {
        return this._subjectsLookup[name] ?? null;
    }

    updateDataTree(data: DatasetData): boolean {
        if (this._data.name === data.name) {
            this._resetSubjects();
            this._data = data;
            this._processSubjects();
            return true;
        }

        for (const subject of this._subjects) {
            if (subject.updateDataTree(data)) {
                return true;
            }
        }

        return false;
    }

    private _resetSubjects(): void {
        this._subjects = [];
        this._subjectsLookup = {};
    }

    private _processSubjects(): void {
        for (const subjectData of this._data.subject ?? []) {
            const subject = new FHIRSubject(subjectData, {
                parentDataset: this,
                parentSubject: this._parentSubject
            });

            this._subjects.push(subject);
            this._subjectsLookup[subjectData.name] = subject;
        }
    }
}
