import { inject, Injectable } from "@angular/core";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { LgConfirmationDialog } from "@logex/framework/ui-core";

@Injectable({ providedIn: "root" })
export class AppDialogService {
    private _confirmationDialog = inject(LgConfirmationDialog);
    private _translateService = inject(LgTranslateService);

    public async displayErrorMessage(message: string, action?: () => void): Promise<number> {
        const title = this._translateService.translate("APP._Error.Title");

        const value = await this._confirmationDialog.alert(title, message);
        if (action) {
            action();
        }

        return value;
    }

    public displayMissingBodyErrorMessage(): Promise<number> {
        return this.displayErrorMessage("Response has been received without body.");
    }

    public displaySaveInProgressMessage(): Promise<number> {
        return this._confirmationDialog.alertLC(
            "APP._SubjectEdit.Save_in_progress_title",
            "APP._SubjectEdit.Save_in_progress_text"
        );
    }

    public displayLockedSubjectMessage(): Promise<number> {
        return this._confirmationDialog.confirmLC(
            "APP._SubjectEdit.Subject_locked_title",
            "APP._SubjectEdit.Subject_locked_text",
            null,
            {
                dialogType: "alert",
                button1: this._translateService.translate("APP._SubjectEdit.Subject_locked_leave"),
                button2: this._translateService.translate("APP._SubjectEdit.Subject_locked_view"),
                closeOnOverlayClick: true
            }
        );
    }

    public displayCannotProceedMessage(variablesInfo: string | undefined): Promise<number> {
        return this._confirmationDialog.alert(
            this._translateService.translate("APP._SubjectEdit._CannotProceedDialog.Title"),
            this._translateService.translate("APP._SubjectEdit._CannotProceedDialog.Text", {
                variables: variablesInfo
            })
        );
    }

    public displayNoAccessErrorMessage(): Promise<number> {
        return this._confirmationDialog.alert(
            this._translateService.translate("APP._Error.Title"),
            this._translateService.translate("APP._SelectProject.No_access_error")
        );
    }
}
