import { DatasetData, SubjectData } from "@fhir-api";

import { FHIRSubjectDataset } from "./fhir-subject-dataset";
import { FHIRSubjectVariable } from "./fhir-subject-variable";

import { Project, ProjectVariable } from "../metadata";
import { FhirDataset } from "./fhir-dataset";
import { FhirAttribute } from "./fhir-attribute";

interface FHIRSubjectConstructorOptions {
    parentDataset: FHIRSubjectDataset;
    parentSubject?: FHIRSubject | null;
    project?: FhirDataset | null;
}
export class FHIRSubject {
    static create(data: SubjectData, options: FHIRSubjectConstructorOptions): FHIRSubject {
        return new FHIRSubject(data, options);
    }

    static createEmpty({
        parentDataset,
        project,
        keyVariables,
        defaultValues
    }: {
        parentDataset: FHIRSubjectDataset;
        project?: FhirDataset | null;
        keyVariables?: FhirAttribute[];
        defaultValues?: Record<string, string | null>;
    }): FHIRSubject {
        const data = {
            name: "$new",
            variable: (keyVariables ?? []).map(variable => {
                const value = defaultValues?.[variable.name] ?? null;
                return {
                    name: variable.name,
                    value: value === null ? "" : value
                };
            })
        };

        return FHIRSubject.create(data, { project, parentDataset });
    }

    static createUrlFromApiPath(
        projectUrl: string,
        datasetApiPath: string,
        subjectApiPath: string
    ): string {
        const datasetNames = datasetApiPath.split(".").slice(1);
        const subjectNames = subjectApiPath.split(".");

        let url = `${projectUrl}/subject`;

        for (let i = 0; i < subjectNames.length; i++) {
            url += `/${subjectNames[i]}${datasetNames[i] ? `/${datasetNames[i]}` : ""}`;
        }

        return url;
    }

    get name(): string {
        return this._data.name;
    }

    get label(): string {
        return this._data.label || "-";
    }

    get url(): string {
        return this._url;
    }

    get apiPath(): string {
        return this._apiPath;
    }

    get urlSegments(): string[] {
        return [this._parentDataset.name, this._data.name];
    }

    get canDelete(): boolean {
        const value = Number(this._data["can-delete"] ?? 1);
        return value !== 0 && !Number.isNaN(value);
    }

    get parentDataset(): FHIRSubjectDataset {
        return this._parentDataset;
    }

    get datasets(): FHIRSubjectDataset[] {
        return this._subjectDatasets;
    }

    get parentSubject(): FHIRSubject | null {
        return this._parentSubject;
    }

    get variables(): FHIRSubjectVariable[] {
        return this._subjectVariables;
    }

    private _url = "";
    private _apiPath = "";
    private _parentDataset: FHIRSubjectDataset;
    private _parentSubject: FHIRSubject | null = null;
    private _subjectDatasets: FHIRSubjectDataset[] = [];
    private _subjectDatasetsLookup: Record<string, FHIRSubjectDataset> = {};
    private _subjectVariables: FHIRSubjectVariable[] = [];
    private _subjectVariablesLookup: Record<string, FHIRSubjectVariable> = {};

    constructor(
        private _data: SubjectData,
        { parentDataset, parentSubject }: FHIRSubjectConstructorOptions
    ) {
        if (parentDataset) {
            this._url = `${parentDataset.url}`;
        }

        if (!parentSubject) {
            // root subject
            this._url += `/subject/${this.name}`;
        } else {
            this._url += `/${this.name}`;
        }

        this._parentDataset = parentDataset;
        this._parentSubject = parentSubject ?? null;

        if (this._parentSubject) {
            this._apiPath = `${this._parentSubject.apiPath}.${this.name}`;
        } else {
            this._apiPath = this.name;
        }

        this._processDatasets();
        this._processVariables();
    }

    getClone(): FHIRSubject {
        return FHIRSubject.create(this._data, { parentDataset: this._parentDataset });
    }

    getDataset(name: string): FHIRSubjectDataset | null {
        return this._subjectDatasets.find(dataset => dataset.name === name) ?? null;
    }

    getVariable(variableName: string): FHIRSubjectVariable | null {
        return this._subjectVariables.find(variable => variable.name === variableName) ?? null;
    }

    getValidationMessages(project: FhirDataset, { includeChildren = false } = {}): string[] {
        const subjectMessages = this._subjectVariables
            .filter(subjectVariable => {
                const variable = project.getVariable(subjectVariable.name);
                return (
                    variable?.getIsVisible(subjectVariable) &&
                    !variable?.getIsValid(subjectVariable)
                );
            })
            .map(subjectVariable => {
                const variable = project.getVariable(subjectVariable.name);
                return (
                    (variable?.getValidations(subjectVariable) ?? [])
                        .map(validationData => validationData.message)
                        .filter(message => message != null && message.trim().length > 0)
                        .join(", ") ?? []
                );
            });

        const childrenMessages = includeChildren
            ? this._subjectDatasets.flatMap(dataset =>
                  dataset.subjects.flatMap(subject =>
                      subject.getValidationMessages(project, { includeChildren })
                  )
              )
            : [];
        return [...subjectMessages, ...childrenMessages];
    }

    setVariableValue(variableName: string, value: string): void {
        const variable = this.getVariable(variableName);
        if (variable) {
            variable.value = value;
        }
    }

    updateDataTree(data: DatasetData): boolean {
        for (const dataset of this._subjectDatasets) {
            if (dataset.updateDataTree(data)) {
                return true;
            }
        }

        return false;
    }

    private _processDatasets(): void {
        for (const datasetData of this._data.dataset ?? []) {
            const dataEntryDataset = new FHIRSubjectDataset(datasetData, {
                parentSubject: this,
                parentDataset: this._parentDataset
            });
            this._subjectDatasets.push(dataEntryDataset);
            this._subjectDatasetsLookup[datasetData.name] = dataEntryDataset;
        }
    }

    private _processVariables(): void {
        for (const variableData of this._data.variable ?? []) {
            const variable = new FHIRSubjectVariable(variableData, { parentSubject: this });
            this._subjectVariables.push(variable);
            this._subjectVariablesLookup[variable.name] = variable;
        }
    }
}
