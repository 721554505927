import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { AppConfiguration } from "./app-configuration";
import { firstValueFrom } from "rxjs";

@Injectable({ providedIn: "root" })
export class AppConfigurationLoaderService {
    private _http = inject(HttpClient);

    loadConfig(): Promise<AppConfiguration> {
        return firstValueFrom(this._http.get<AppConfiguration>("assets/config/configuration.json"));
    }
}
