import {
    DataEntryUIControlType,
    ScmAttributeTypeEnum,
    FormConfigurationEntityAttribute,
    FormConfigurationOptionsDirection,
    FormConfigurationVisibilityEnum,
    SubjectVariableValidationData,
    ScmEntityAttribute,
    ScmAttachedExtension,
    isEnumScmAttribute
} from "@fhir-api";
import { FHIRSubjectVariable } from "./fhir-subject-variable";
import { DatePipe } from "@angular/common";
import decodeHtmlEntities from "@app/util/html-util";

export interface IconResource {
    disabled?: boolean;
    src: string;
    tooltip: string;
}

export interface OptionImageDefinition {
    src: string;
    title?: string | null;
    titleColor?: string | null;
    dotColor: string | null;
    width: number | null;
    height: number | null;
}

export interface FhirOptionset {
    options: FhirAttributeOption[];
}

export interface FhirAttributeOption {
    value: string;
    label: string;
    description?: string;
    disabled: boolean;
    image?: OptionImageDefinition;
}

interface FhirAttributeConstructorOptions {
    unselectableOptions?: string[];
}

export class FhirAttribute {
    get type(): ScmAttributeTypeEnum {
        return this._scm.dataType;
    }

    get name(): string {
        return this._scm.name;
    }

    get directPii(): boolean {
        return this._scm.directPii ?? false;
    }

    get label(): string {
        return this._formConfiguration?.label || this._scm.name;
    }

    get visibility(): FormConfigurationVisibilityEnum {
        return FormConfigurationVisibilityEnum.Visible;
    }

    get inputControl(): DataEntryUIControlType {
        return this._formConfiguration?.inputControl || this._inferInputControl(this._scm.dataType);
    }

    get interesting(): boolean {
        return false;
    }

    get scm(): ScmEntityAttribute {
        return this._scm;
    }

    get formConfiguration(): FormConfigurationEntityAttribute | undefined {
        return this._formConfiguration;
    }

    get isExternalOptionset(): boolean {
        return false;
    }

    get readonly(): boolean {
        return this._formConfiguration?.readonly || false;
    }

    get defaultValue(): string {
        return this._formConfiguration?.defaultValue || "";
    }

    get required(): boolean {
        return this._scm.required ?? false;
    }

    get format(): string | null {
        return null;
    }

    get help(): string {
        return "";
    }

    get length(): number {
        return Number(20);
    }

    get category(): string {
        return "test";
    }

    get categoryLabel(): string {
        return "test-category";
    }

    get controlVariant(): FormConfigurationOptionsDirection {
        return (
            this._formConfiguration?.optionsDirection ?? FormConfigurationOptionsDirection.Vertical
        );
    }

    get forceOptionsetValue(): boolean {
        return true;
    }

    get optionset(): ScmAttachedExtension | undefined {
        if (!isEnumScmAttribute(this._scm)) return undefined;
        const enumName = this._scm.referenceExtension?.enumName;
        return this._scmAttachedExtensions.find(extension => extension.tableName === enumName);
    }

    get hasOptions(): boolean {
        return this.optionset?.enumOptions !== undefined && this.optionset?.enumOptions?.length > 0;
    }

    get isUnskippable(): boolean {
        return false;
    }

    get isBarcodeScannerInput(): boolean {
        return false;
    }

    get resourceIcon(): IconResource | undefined {
        return undefined;
    }

    private _options: FhirAttributeOption[] = [];
    private _unselectableOptions?: string[];

    private _datePipe: DatePipe = new DatePipe("nl-NL");

    constructor(
        private _scm: ScmEntityAttribute,
        private _scmAttachedExtensions: ScmAttachedExtension[],
        private _formConfiguration?: FormConfigurationEntityAttribute,
        { unselectableOptions }: FhirAttributeConstructorOptions = {}
    ) {
        this._unselectableOptions = unselectableOptions;

        this._setOptions();
    }

    getUIControlType(defaultControl: DataEntryUIControlType): DataEntryUIControlType {
        return this.inputControl || defaultControl;
    }

    getValue(subjectVariable?: FHIRSubjectVariable | null): string {
        if (!subjectVariable) return this.defaultValue;
        return subjectVariable?.value || this.defaultValue;
    }

    getValueLabel(subjectVariable?: FHIRSubjectVariable | null): string {
        if (this.hasOptions) {
            const option = this._options.find(o => o.value === this.getValue(subjectVariable));
            return option?.label ?? "";
        }
        return this.getFormattedValue(this.getValue(subjectVariable));
    }

    getIsValid(subjectVariable?: FHIRSubjectVariable | null): boolean {
        if (!subjectVariable) return true;
        return subjectVariable.valid;
    }

    getIsLocked(subjectVariable?: FHIRSubjectVariable | null): boolean {
        if (this.visibility === FormConfigurationVisibilityEnum.Readonly) return true;
        return subjectVariable?.locked ?? false;
    }

    getIsVisible(subjectVariable?: FHIRSubjectVariable | null): boolean {
        if (this.visibility === FormConfigurationVisibilityEnum.Hidden) return false;
        if (!subjectVariable) return true;
        return subjectVariable.visibility !== "hidden" && subjectVariable.visible;
    }

    getIsCardinalityReached(subjectVariable?: FHIRSubjectVariable | null): boolean {
        if (!subjectVariable) return false;
        return subjectVariable.cardinalityReached;
    }

    getIsWriteable(subjectVariable?: FHIRSubjectVariable | null): boolean {
        if (!subjectVariable) return true;

        const canCreate = subjectVariable.canCreate;

        return (
            !this.getIsLocked(subjectVariable) &&
            subjectVariable.visible &&
            subjectVariable.visibility !== "readonly" &&
            subjectVariable.visibility !== "hidden" &&
            canCreate
        );
    }

    getCanCreate(subjectVariable?: FHIRSubjectVariable | null): boolean {
        if (!subjectVariable) return false;
        return subjectVariable.canCreate;
    }

    getValidations(
        subjectVariable?: FHIRSubjectVariable | null
    ): SubjectVariableValidationData[] | null {
        if (!subjectVariable) return null;
        return subjectVariable.validations?.length ? subjectVariable.validations : null;
    }

    /** getHiddenOptions(subjectVariable?: FHIRSubjectVariable | null): Set<string> | null {
        if (!subjectVariable) return null;

        const hiddenOptions = subjectVariable.hiddenOptions;
        const defaultHiddenOptions =
            this.optionset?.visibility === FormConfigurationOptionVisibilityEnum.Hidden
                ? (this._unselectableOptions ?? [])
                : [];

        const hidden = [...hiddenOptions, ...defaultHiddenOptions];
        if (hidden.length === 0) return null;

        return new Set(hidden);
    } **/

    getOptions(subjectVariable?: FHIRSubjectVariable | null): FhirAttributeOption[] {
        // if (!subjectVariable)
        return this._options; // hardcoded for now, because the visibility is not yet implemented

        /**const hidden = this.getHiddenOptions(subjectVariable);
        if (hidden == null) return this._options;

        return this._options.filter(option => !hidden.has(option.value));**/
    }

    getOptionImage(subjectVariable?: FHIRSubjectVariable | null): OptionImageDefinition | null {
        if (!subjectVariable) return null;

        const options = this.getOptions(subjectVariable);
        if (!options) return null;

        const option = options.find(o => o.value === subjectVariable.value);
        return option?.image ?? null;
    }

    getFormattedValue(value: string): string {
        if (this.type === ScmAttributeTypeEnum.Date) {
            return this._datePipe.transform(value, "dd-MM-yyyy") ?? "";
        }
        if (this.type === ScmAttributeTypeEnum.DateTime) {
            return this._datePipe.transform(value, "dd-MM-yyyy HH:mm") ?? "";
        }
        return value;
    }

    private _inferInputControl(dataType: ScmAttributeTypeEnum): DataEntryUIControlType {
        switch (dataType) {
            case ScmAttributeTypeEnum.Boolean:
                return DataEntryUIControlType.Checkbox;
            case ScmAttributeTypeEnum.Date:
                return DataEntryUIControlType.Date;
            case ScmAttributeTypeEnum.DateTime:
                return DataEntryUIControlType.DateTime;
            case ScmAttributeTypeEnum.Enum:
                const optionsCount = this.optionset?.enumOptions?.length || 0;
                if (optionsCount <= 3) {
                    return DataEntryUIControlType.Radio;
                } else if (optionsCount < 6) {
                    return DataEntryUIControlType.Select;
                } else {
                    return DataEntryUIControlType.Autocomplete;
                }
            case ScmAttributeTypeEnum.String:
                return DataEntryUIControlType.Text;
            default:
                return DataEntryUIControlType.Text;
        }
    }

    private _setOptions(): void {
        if (isEnumScmAttribute(this._scm)) {
            this._options =
                this.optionset?.enumOptions?.map(option => {
                    const variableOption: FhirAttributeOption = {
                        value: option[0],
                        label: decodeHtmlEntities(option[1]) ?? "",
                        description: decodeHtmlEntities(option[1]) ?? "",
                        disabled: false // hardcoded to false, because the visibility is not yet implemented
                        /**this.optionset?.visibility ===
                                FormConfigurationOptionVisibilityEnum.Disabled &&
                            (this._unselectableOptions ?? []).includes(option.id)**/
                    };

                    return variableOption;
                }) || [];
        }
    }
}
