export const enum ScmAttributeTypeEnum {
    String = "string",
    Integer = "integer",
    Float = "decimal",
    Date = "Date",
    Enum = "Enum",
    DateTime = "datetime",
    Boolean = "boolean",
    Paragraph = "paragraph", // will it exist in the FC?
    Dataset = "dataset", // will it exist in the FC?
    ExternalDataset = "external-dataset" // will it exist in the FC?
}

export type ScmAttributeTypeNumeric = ScmAttributeTypeEnum.Integer | ScmAttributeTypeEnum.Float;
export type ScmAttributeTypeDate = ScmAttributeTypeEnum.DateTime | ScmAttributeTypeEnum.Date;
export type ScmAttributeTypeReference =
    | ScmAttributeTypeEnum.Enum
    | ScmAttributeTypeReferenceExternal;
export type ScmAttributeTypeReferenceExternal =
    | ScmAttributeTypeEnum.Dataset
    | ScmAttributeTypeEnum.ExternalDataset;
export type ScmAttributeTypeRanged = ScmAttributeTypeNumeric | ScmAttributeTypeDate;

export function isStringScmAttribute(
    attribute: ScmEntityAttribute
): attribute is ScmEntityAttributeString {
    return attribute.dataType === ScmAttributeTypeEnum.String;
}

export function isRangedScmAttribute(
    attribute: ScmEntityAttribute
): attribute is ScmEntityAttributeRanged {
    return "rangeExtension" in attribute;
}

export function isEnumScmAttribute(
    attribute: ScmEntityAttribute
): attribute is ScmEntityAttributeEnum {
    return "referenceExtension" in attribute;
}

export function isDecimalScmAttribute(
    attribute: ScmEntityAttribute
): attribute is ScmEntityAttributeDecimal {
    return "decimalExtension" in attribute;
}

export interface ScmEntityAttributeBase {
    name: string;
    dataType: ScmAttributeTypeEnum;
    description?: string;
    directPii?: boolean;
    required?: boolean;
    preferredOrder?: number;
    encryptedValues?: boolean;
    regexValidation?: string | null;
    interpretAs?: string | null;
    includeWhen?: string | null;
    stringExtension?: null | ScmEntityAttributeStringExtension;
    rangeExtension?: null | ScmEntityAttributeRangeExtension<this["dataType"]>;
    qualityRules?: string[];
}

export interface ScmEntityAttributeStringExtension {
    maxLength?: number;
}

export interface ScmEntityAttributeDecimalExtension {
    precision: number;
    scale: number;
}

export interface ScmEntityAttributeReferenceExtension {
    enumName: string;
}

export interface ScmEntityAttributeReferenceExtensionExternal
    extends ScmEntityAttributeReferenceExtension {
    enumId: string;
    externalSystem: string;
    dataVersion?: string;
    referenceDateTime?: string;
    sortBy?: string;
    matchType?: string;
}

export interface ScmEntityAttributeRangeExtension<T extends ScmAttributeTypeEnum> {
    minValue?: T extends ScmAttributeTypeNumeric ? number : string;
    minInclusive?: boolean;
    maxValue?: T extends ScmAttributeTypeNumeric ? number : string;
    maxInclusive?: boolean;
}

export interface ScmEntityAttributeString extends ScmEntityAttributeBase {
    dataType: ScmAttributeTypeEnum.String;
    stringExtension?: ScmEntityAttributeStringExtension;
}

export interface ScmEntityAttributeRanged extends ScmEntityAttributeBase {
    dataType: ScmAttributeTypeRanged;
    rangeExtension?: ScmEntityAttributeRangeExtension<
        this["dataType"] extends ScmAttributeTypeNumeric
            ? ScmAttributeTypeNumeric
            : ScmAttributeTypeDate
    >;
}

export interface ScmEntityAttributeDecimal extends ScmEntityAttributeRanged {
    dataType: ScmAttributeTypeEnum.Float;
    decimalExtension?: ScmEntityAttributeDecimalExtension;
}

export interface ScmEntityAttributeEnum extends ScmEntityAttributeBase {
    dataType: ScmAttributeTypeReference;
    referenceExtension?: this["dataType"] extends ScmAttributeTypeReferenceExternal
        ? ScmEntityAttributeReferenceExtensionExternal
        : ScmEntityAttributeReferenceExtension;
}

export type ScmEntityAttribute =
    | ScmEntityAttributeString
    | ScmEntityAttributeEnum
    | ScmEntityAttributeDecimal
    | ScmEntityAttributeRanged
    | ScmEntityAttributeBase;

export enum ScmAttachedExtensionTypeEnum {
    Enum = "Enum"
}

export enum ScmIdType {
    Integer = "Integer"
}

export interface ScmAttachedExtensionOption {
    id: string;
    name: string;
}

export enum ScmContractStatus {
    Archived = "archived",
    Published = "published",
    Draft = "draft"
}

export interface ScmAttachedExtension {
    version?: string;
    attachmentType?: ScmAttachedExtensionTypeEnum;
    tableName: string;
    author?: string;
    maintainer?: string;
    description?: string | null;
    status: ScmContractStatus;
    idType: ScmIdType;
    sortBy?: string | null;
    primaryKey?: string;
    enumOptions?: [string, string][]; // TODO later will be this: ScmAttachedExtensionOption[];
}

export interface ScmEntity {
    type: "entity";
    primaryKey: string;
    childContract: {
        draftName: string | null;
        draftBase: string | null;
        uuid: string;
        type: "entity";
        businessId: string;
        name: string;
        description?: string;
        author: string;
        maintainer?: string;
        requiredBindings?: null;
        status: ScmContractStatus;
        version?: string | null;
        storageType: string;
        businessAlias?: string;
        attributes: ScmEntityAttribute[];
        contractValidations?: string[];
        temporaries?: string[];
        attachedExtension?: ScmAttachedExtension[];
    };
}

export interface ScmEntityList {
    preferredOrder?: number;
    required?: boolean;
    storageType?: string;
    rootEntityList?: string | null;
    tableName?: string | null;
    instancePointer?: string | null;
    primaryKey?: string | null;
    sortField?: string | null;
    sortBy?: string | null;
    dataVersion?: string | null;
    referenceDateTime?: string | null;
    childContract: {
        draftName: string | null;
        draftBase: string | null;
        uuid: string;
        type: "entitylist";
        businessId: string;
        name: string;
        description?: string;
        author: string;
        maintainer?: string;
        version?: string | null;
        status: ScmContractStatus;
        childContract: ScmEntity;
        validations?: string[];
    };
}

export interface ScmKeyedRelation {
    pair: {
        parentKeyAttribute: string;
        linkKeyAttribute: string;
    };
    required?: boolean;
}

export interface ScmEntityGroup {
    preferredOrder?: number;
    required?: boolean;
    thresholdOverrides?: string[];
    childContract: {
        uuid: string;
        type: "entitylistgroup";
        businessId: string;
        name: string;
        description?: string;
        author: string;
        version?: string | null;
        maintainer?: string;
        status: ScmContractStatus;
        childContracts: ScmEntityList[];
        validations?: string[];
        keyedRelations?: ScmKeyedRelation[];
        draftName: string | null;
        draftBase: string | null;
    };
}
export type Scm = ScmPublished | ScmDraft;

export interface ScmBase {
    uuid: string;
    businessId: string;
    status: ScmContractStatus;
    name: string;
    description?: string;
    intendedProject?: string | null;
    type: "dataset";
    author: string;
    maintainer: string;
    childContracts: ScmEntityGroup[];
    contractId?: string;
    version?: string | null;
    draftName: string | null;
    draftBase: string | null;
}

export interface ScmPublished extends ScmBase {
    status: ScmContractStatus.Published | ScmContractStatus.Archived;
    version: string;
}

export interface ScmDraft extends ScmBase {
    status: ScmContractStatus.Draft;
    draftName: string;
    draftBase: string;
}

export type GetScmResponseData = { contract: Scm };
