import { AppProjectsService } from "@app/services/app-projects.service";
import { FhirEntityListGroup } from "./fhir-entity-list-group";
import { DataEntryUIControlType, FormConfiguration, Scm } from "@fhir-api";
import { FhirAttribute } from "./fhir-attribute";
import { FhirEntity } from "./fhir-entity";

interface DatasetConstructorOptions {
    organizationUri?: string;
}

export class FhirDataset {
    private _product: string | null = null;
    private _url = "";

    get name(): string {
        return "dhba-2023"; // TODO: hardcoded to load correct subject change to: this._formConfiguration.name;
    }

    get type(): string {
        return "dataset";
    }

    get label(): string {
        return "dhba 2023";
    }

    get description(): string {
        return "";
    }

    get defaultLanguage(): string | null {
        return null;
    }

    get rootDataset(): FhirEntity | null {
        return this._entitiesLookup["patient"] ?? null; // hardcoded for now, no other way to do it
    }

    get rootDatasetName(): string {
        return this.rootDataset?.name ?? "";
    }

    get datasets(): FhirEntity[] {
        return this._entities;
    }

    get url(): string {
        return this._url;
    }

    get defaultControl(): DataEntryUIControlType {
        return DataEntryUIControlType.Text;
    }

    get skipEmptyCreationDialog(): boolean {
        return false;
    }

    get hideSubjectCountInSubjectList(): boolean {
        return false;
    }

    get scm(): Scm {
        return this._scm;
    }

    get formConfiguration(): FormConfiguration | undefined {
        return this._formConfiguration;
    }

    private _attributes: FhirAttribute[] = [];
    private _attributesLookup: Record<string, FhirAttribute> = {};

    private _rootEntity: FhirEntity | null = null;
    private _entities: FhirEntity[] = [];
    private _entitiesLookup: Record<string, FhirEntity> = {};

    constructor(
        private _projectsService: AppProjectsService,
        private _scm: Scm,
        private _formConfiguration?: FormConfiguration,
        { organizationUri }: DatasetConstructorOptions = {}
    ) {
        this._connectEntities();
        this._setVariables();
        this._setProduct();

        if (organizationUri !== undefined) {
            this._url = `/registry/${organizationUri}/${this.name}`;
        }
    }

    getDataset(name: string): FhirEntity | null {
        return this._entitiesLookup[name] ?? null;
    }

    getDatasetSections(name: string): FhirEntity[] {
        return this._entities ?? [];
    }

    getVariable(name: string): FhirAttribute | null {
        return this._attributesLookup[name] ?? null;
    }

    getInterestingVariable(): FhirAttribute | null {
        return this._attributes.find(attribute => attribute.interesting) ?? null;
    }

    getProduct(): string | null {
        return this._product;
    }

    getIconResource(name: string): {
        disabled?: boolean;
        src: string;
        tooltip: string;
    } | null {
        return null;
    }

    private _connectEntities(): void {
        for (let index in this._scm?.childContracts) {
            const entityListGroup = new FhirEntityListGroup(
                this._scm.childContracts[index],
                this._formConfiguration?.childContract?.[index]
            );
            entityListGroup.entityLists
                .map(entityList => entityList.entity)
                .flat(1)
                .forEach(entity => {
                    this._entities.push(entity);
                    this._entitiesLookup[entity.name] = entity;
                });
        }
    }

    private _setVariables(): void {
        for (const entity of this._entities) {
            this._attributes.push(...[entity.attributes].flat(1));
            this._attributesLookup = this._attributes.reduce<Record<string, FhirAttribute>>(
                (lookup, attribute) => {
                    lookup[attribute.name] = attribute;
                    return lookup;
                },
                {}
            );
        }
    }

    private _setProduct(): void {
        const project = this._projectsService.getProjectByUri(this.name);
        if (project?.properties.product === undefined) return;

        this._product = project.properties.product.replace("Product.", "");
    }
}
