import { XMLLayoutDefinition, XMLSectionDefinition, DefaultVisibility } from "@fhir-api";
import { ProjectSectionCategory } from "./project-section-category";
import { ProjectVariable } from "./project-variable";
import { FHIRSubject } from "../fhir-middleware";

interface SectionConstructorOptions {
    visibilities?: DefaultVisibility[];
}

export class ProjectSection {
    get name(): string {
        return this._definition.name;
    }

    get label(): string {
        return this._definition.label?.[0].content ?? "";
    }

    get variables(): ProjectVariable[] {
        return this._variables;
    }

    get categories(): ProjectSectionCategory[] {
        return this._categories;
    }

    get layout(): XMLLayoutDefinition | undefined {
        return this._definition.layout;
    }

    private _variables: ProjectVariable[] = [];
    private _variablesLookup: Record<string, ProjectVariable> = {};

    private _categories: ProjectSectionCategory[] = [];
    private _visibilities?: DefaultVisibility[];

    constructor(
        private _definition: XMLSectionDefinition,
        { visibilities }: SectionConstructorOptions = {}
    ) {
        this._visibilities = visibilities;

        this._set();
    }

    getIsVisible(subject: FHIRSubject | null): boolean {
        for (const variable of this._variables) {
            const subjectVariable = subject?.variables.find(fv => fv.name === variable.name);
            if (variable.getIsVisible(subjectVariable)) return true;
        }

        return false;
    }

    getValidationMessages(subject: FHIRSubject): string[] {
        return this._variables
            .filter(variable => {
                const subjectVariable = subject.getVariable(variable.name);
                return (
                    (variable?.getIsVisible(subjectVariable) ?? false) &&
                    (!variable?.getIsValid(subjectVariable) ?? false)
                );
            })
            .map(variable => {
                const subjectVariable = subject.getVariable(variable.name);
                return (variable?.getValidations(subjectVariable) ?? [])
                    .map(validationData => validationData.message)
                    .filter(message => message?.trim().length)
                    .join(", ");
            });
    }

    getInvalidUnskippableVariables(subject: FHIRSubject): ProjectVariable[] {
        return this._variables.filter(variable => {
            const subjectVariable = subject.getVariable(variable.name);
            return variable.isUnskippable && subjectVariable?.visible && !subjectVariable?.valid;
        });
    }

    private _set(): void {
        const categoryLookup: Record<string, ProjectSectionCategory> = {};

        for (const variableData of this._definition.variable) {
            const unselectableOptions = this._getUnselectableOptions(variableData.name);
            const dataentryVariable = new ProjectVariable(variableData, {
                unselectableOptions
            });

            this._variables.push(dataentryVariable);
            this._variablesLookup[variableData.name] = dataentryVariable;

            const { category, categoryLabel } = dataentryVariable;

            let sectionCategory = categoryLookup[category];

            if (!sectionCategory) {
                sectionCategory = new ProjectSectionCategory(category, categoryLabel);

                categoryLookup[category] = sectionCategory;

                this._categories.push(sectionCategory);
            }

            sectionCategory.addVariable(dataentryVariable);
        }
    }

    private _getUnselectableOptions(variableName: string): string[] | undefined {
        return this._visibilities
            ?.filter(visibility => visibility.name === variableName)
            ?.flatMap(visibility => visibility.option)
            .map(option => option.value);
    }
}
