import { Injectable, inject } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import {
    ApplicationInsightsCustomData,
    IApplicationInsightsConfiguration
} from "@logex/framework/lg-application";

import { AppConfigurationService } from "../configuration/app-configuration.service";

@Injectable({ providedIn: "root" })
export class AppApplicationInsightsConfigurationService
    implements IApplicationInsightsConfiguration
{
    private _appConfigurationService = inject(AppConfigurationService);
    readonly userId = new BehaviorSubject<string | null>(null);

    autoInit = true;
    instrumentationKey = "";
    correlationHeaderExcludedDomains: string[] | undefined;

    private _organizationUri: string | null = null;
    private _registryName: string | null = null;

    constructor() {
        const appInsights = this._appConfigurationService.getApplicationInsightsConfig();

        this.instrumentationKey = appInsights.instrumentationKey ?? "";
        this.correlationHeaderExcludedDomains = appInsights.correlationHeaderExcludedDomains;
    }

    doNotDoAiTracking(): boolean {
        return this._appConfigurationService.getDoNotTrack() || !this.instrumentationKey;
    }

    setUserID(id: string): void {
        this.userId.next(id);
    }

    setProject(organizationUri: string | null, registryName: string | null): void {
        this._organizationUri = organizationUri;
        this._registryName = registryName;
    }

    customData(): Record<string, string | null> {
        return {
            [ApplicationInsightsCustomData.Environment]:
                this._appConfigurationService.getEnvironment() || "unknown",
            ["organizationUri"]: this._organizationUri,
            ["registryName"]: this._registryName
        };
    }
}
