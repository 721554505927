import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ServerGatewayBase } from "@logex/framework/lg-application";

import {
    AuthorizationExtendedPermission,
    OrganizationIdentifier,
    MRDM_AUTH_API_URL,
    AuthorizationProfile
} from "./mrdm-auth-api.types";

@Injectable({
    providedIn: "root"
})
export class MrdmAuthApiService extends ServerGatewayBase {
    constructor(@Inject(MRDM_AUTH_API_URL) baseUrl: string) {
        super();
        this._setBaseUrl(baseUrl);
    }

    getProfile(): Observable<AuthorizationProfile> {
        return this._get<AuthorizationProfile>("api/v2/user/profile");
    }

    searchPermissions(
        params: {
            applicationInstances?: string[];
            products?: string[];
            organizations?: string[];
            organizationIdentifierType?: OrganizationIdentifier;
        } = {}
    ): Observable<AuthorizationExtendedPermission[]> {
        params.applicationInstances = params.applicationInstances ?? [];
        params.products = params.products ?? [];
        params.organizations = params.organizations ?? [];
        params.organizationIdentifierType = params.organizationIdentifierType ?? "Cic";
        return this._post<AuthorizationExtendedPermission[]>("api/v2/user/permissions/search", {
            body: { ...params }
        });
    }
}
