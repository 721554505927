import { SubjectVariableData, SubjectVariableValidationData } from "@fhir-api";
import { FHIRSubject } from "./fhir-subject";

interface FHIRSubjectVariableConstructorOptions {
    parentSubject: FHIRSubject;
}
export class FHIRSubjectVariable {
    static create(
        data: SubjectVariableData,
        options: FHIRSubjectVariableConstructorOptions
    ): FHIRSubjectVariable {
        return new FHIRSubjectVariable(data, options);
    }

    get parentSubject(): FHIRSubject {
        return this._parentSubject;
    }

    get name(): string {
        return this._data.name;
    }

    set value(value: string) {
        this._data.value = value;
    }

    get value(): string | null {
        return this._data.value ?? null;
    }

    get locked(): boolean {
        return this._data.locked === "1";
    }

    get visibility(): "visible" | "hidden" | "readonly" | undefined {
        return this._data.visibility ?? "visible";
    }

    get visible(): boolean {
        return !this._data.visible || this._data.visible === "1";
    }

    get hiddenOptions(): string[] {
        return (
            this._data.option
                ?.filter(option => option.visible === "0")
                .map(option => option.value ?? "")
                .filter(value => value.length > 0) ?? []
        );
    }

    get valid(): boolean {
        return this._data.validation?.every(v => v.status === "1") ?? true;
    }

    get validations(): SubjectVariableValidationData[] {
        return this._data.validation ?? [];
    }

    set validations(validations: SubjectVariableValidationData[]) {
        this._data.validation = validations;
    }

    get cardinalityReached(): boolean {
        return this._data.isCardinalityReached === "1";
    }

    get canCreate(): boolean {
        return !this._data.canCreate || this._data.canCreate === "1";
    }

    private _parentSubject: FHIRSubject;

    constructor(
        private _data: SubjectVariableData,
        { parentSubject }: FHIRSubjectVariableConstructorOptions
    ) {
        this._parentSubject = parentSubject;
    }
}
