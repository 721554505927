import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class AppStorageService {
    private _fallbackSessionStorage: Record<string, string> = {};

    getFromSessionStorage(key: string): string | null {
        try {
            return sessionStorage.getItem(key);
        } catch (err) {
            return this._fallbackSessionStorage[key] ?? null;
        }
    }

    setToSessionStorage(key: string, value: string): void {
        try {
            sessionStorage.setItem(key, value);
        } catch (err) {
            this._fallbackSessionStorage[key] = value;
        }
    }

    removeFromSessionStorage(key: string): void {
        try {
            sessionStorage.removeItem(key);
        } catch (err) {
            delete this._fallbackSessionStorage[key];
        }
    }
}
