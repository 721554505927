import { FormConfigurationEntityList } from "@fhir-api";
import { FhirEntity } from "./entity";
import { FhirEntityListGroup } from "./entity-list-group";
import { ScmEntityList } from "@logex/expression-validator";

export class FhirEntityList {
    get scm(): ScmEntityList {
        return this._scm;
    }

    get formConfiguration(): FormConfigurationEntityList | undefined {
        return this._formConfiguration;
    }

    get entity(): FhirEntity {
        return this._entity;
    }

    get defaultSection(): string {
        return "patient";
    }

    get parentEntityListGroup(): FhirEntityListGroup {
        return this._parentEntityListGroup;
    }

    private _entity: FhirEntity;

    constructor(
        private _parentEntityListGroup: FhirEntityListGroup,
        private _scm: ScmEntityList,
        private _formConfiguration?: FormConfigurationEntityList
    ) {
        this._entity = new FhirEntity(
            this,
            _scm.content.childContract,
            this._formConfiguration?.childContract?.[0]
        );
    }

    createNewSection(): FhirEntityList {
        const keyVariables = this.entity.keyVariables;
        const scm: ScmEntityList = {
            ...this._scm,
            content: {
                ...this._scm.content,
                childContract: {
                    ...this._scm.content.childContract,
                    content: {
                        ...this._scm.content.childContract.content,
                        attributes: this._scm.content.childContract.content.attributes.filter(
                            attribute =>
                                keyVariables.find(variable => variable.name === attribute.name)
                        )
                    }
                }
            }
        };
        const formConfiguration: FormConfigurationEntityList | undefined = this._formConfiguration
            ? {
                  ...this._formConfiguration,
                  childContract: [
                      {
                          ...this._formConfiguration.childContract[0],
                          helpers: undefined
                      }
                  ]
              }
            : undefined;
        const section = new FhirEntityList(this._parentEntityListGroup, scm, formConfiguration);
        return section;
    }
}
