import { NgModule, ModuleWithProviders } from "@angular/core";

@NgModule({
    declarations: [],
    imports: [],
    exports: []
})
export class AuthorizationModule {
    static forRoot(): ModuleWithProviders<AuthorizationModule> {
        return {
            ngModule: AuthorizationModule
        };
    }
}
