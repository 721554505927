import { Component, inject } from "@angular/core";

import { useTranslationNamespace } from "@logex/framework/lg-localization";

import { Auth0AuthorizationService, Auth0User } from "@authorization";

@Component({
    selector: "app-access-denied",
    templateUrl: "./access-denied.component.html",
    styleUrls: ["./access-denied.component.scss"],
    providers: [useTranslationNamespace("APP._AccessDenied")]
})
export class AccessDeniedComponent {
    private _auth0Authorization = inject(Auth0AuthorizationService<Auth0User>);

    _logout(): void {
        this._auth0Authorization.logout();
    }
}
