export interface FormConfigurationLocalizedTextDefinition {
    content?: string;
    language?: string;
}

export const enum FormConfigurationOptionsDirection {
    Vertical = "vertical",
    Horizontal = "horizontal",
    Count = "count"
}

export const enum FormConfigurationOptionVisibilityEnum {
    Disabled = "disabled",
    Hidden = "hidden"
}

export interface FormConfigurationButtonInitialValueItemDefinition {
    set: string;
    source: string;
}

export interface FormConfigurationButtonDefinition {
    label?: string;
    initialvalues?: FormConfigurationButtonInitialValueItemDefinition;
    project?: string;
    action?: "create";
    actionSpecified?: boolean;
    dataset?: string;
    after?: string;
}

export interface FormConfigurationEntityLayout {
    button?: FormConfigurationButtonDefinition[];
    columns?: number;
    columnsSpecified?: boolean;
}

export const enum FormConfigurationVisibilityEnum {
    Visible = "visible",
    Hidden = "hidden",
    Readonly = "readonly"
}

export interface FormConfigurationOptionsetDefinition {
    name: string;
    structure?: string;
    visibility?: FormConfigurationOptionVisibilityEnum;
    search?: string;
    options?: FormConfigurationOptionDefinition[];
}

export interface FormConfigurationOptionDefinition {
    value: string;
    label: FormConfigurationLocalizedTextDefinition[];
    description?: FormConfigurationLocalizedTextDefinition[];
}

export const enum DataEntryUIControlType {
    Radio = "radiobutton",
    ImageRadio = "image-radio",
    Checkbox = "checkbox",
    Select = "dropdown",
    Autocomplete = "autocomplete",
    Textarea = "textarea",
    Text = "text",
    Date = "calendar",
    DateTime = "datetime",
    Integer = "integer",
    Float = "float",
    Dataset = "dataset",
    Paragraph = "paragraph",
    ExternalDatasetCount = "external-dataset-count",
    ExternalDataset = "external-dataset",
    CreateButton = "create-button"
}

export interface FormConfigurationEntityAttribute {
    name: string;
    label: string;
    inputControl: DataEntryUIControlType;
    readonly?: boolean;
    include?: boolean;
    optionsDirection?: FormConfigurationOptionsDirection;
    defaultValue?: string; // Might actually be more types, but from data I have doesn't seem so
}

export const enum FormConfigurationEntityHelperType {
    Setter = "setter"
}

export interface FormConfigurationEntityHelperPositionAbsolute {
    type: "first" | "last";
}

export interface FormConfigurationEntityHelperPositionRelative {
    type: "before" | "after";
    referenceItemName: string;
}

export type FormConfigurationEntityHelperPosition =
    | FormConfigurationEntityHelperPositionAbsolute
    | FormConfigurationEntityHelperPositionRelative;

export interface FormConfigurationEntityHelperBase {
    name: string;
    type: FormConfigurationEntityHelperType;
    label?: string;
    labelVariables?: Record<string, string>;
    position?: FormConfigurationEntityHelperPosition;
}

export interface FormConfigurationEntityHelperSetterOption {
    value: string;
    label: string;
}

export interface FormConfigurationEntityHelperSetter extends FormConfigurationEntityHelperBase {
    type: FormConfigurationEntityHelperType.Setter;
    attributesToSet: string[];
    options: FormConfigurationEntityHelperSetterOption[];
}

export type FormConfigurationEntityHelper = FormConfigurationEntityHelperSetter;

export enum FormConfigurationCreateFromExistingPickerType {
    LatestDate = "latestDate"
}

export interface FormConfigurationCreateFromExistingSettings {
    label?: string;
    excludeKeys?: string[];
    pickerType?: FormConfigurationCreateFromExistingPickerType;
}

export interface FormConfigurationEntityListSettings {
    createFromExisting?: FormConfigurationCreateFromExistingSettings;
}

export interface FormConfigurationEntity {
    type: "entity";
    label?: string;
    contractId: string;
    helpers?: FormConfigurationEntityHelper[];
    attributes: FormConfigurationEntityAttribute[];
}

export interface FormConfigurationEntityList {
    type: "entityList";
    contractId: string;
    settings?: FormConfigurationEntityListSettings;
    childContract: FormConfigurationEntity[];
}

export interface FormConfigurationEntityGroup {
    type: "entityListGroup";
    contractId: string;
    childContract: FormConfigurationEntityList[];
}

export interface FormConfiguration {
    author: string;
    businessId: string;
    childContract: FormConfigurationEntityGroup[];
    contractId?: string;
    maintainer?: string;
    name: string;
    status?: string;
    type?: "dataset";
    uuid?: string;
    version?: string;
}

export type GetFormConfigurationResponseData = [FormConfiguration];
