import {
    DefaultVisibility,
    FormConfigurationEntity,
    FormConfigurationEntityLayout,
    ScmEntity
} from "@fhir-api";
import { FhirAttribute } from "./fhir-attribute";
import { FHIRSubject } from "./fhir-subject";
import { FhirEntityList } from "./fhir-entity-list";
import { FhirSectionCategory } from "./fhir-section-category";
import { is } from "@logex/framework/utilities";

interface FhirEntityConstructorOptions {
    parentEntityList: FhirEntityList | null;
    visibilities?: DefaultVisibility[];
}

export class FhirEntity {
    get name(): string {
        return this._scm.childContract.businessAlias ?? this._scm.childContract.name;
    }

    get label(): string {
        return "TEST ENTITY LABEL";
    }

    get layout(): FormConfigurationEntityLayout | null {
        return null;
    }

    get wordLabel(): string {
        return this.label.toLocaleLowerCase();
    }

    get startLabel(): string {
        if (this.wordLabel.length === 0) {
            return this.wordLabel;
        }
        const word = this.wordLabel;
        return word[0].toLocaleUpperCase() + word.substring(1);
    }

    get indelible(): boolean {
        return false;
    }

    get attributes(): FhirAttribute[] {
        return this._attributes;
    }

    get sections(): FhirEntity[] {
        return this._sections;
    }

    get categories(): FhirSectionCategory[] {
        return this._categories;
    }

    get scm(): ScmEntity {
        return this._scm;
    }

    get formConfiguration(): FormConfigurationEntity | undefined {
        return this._formConfiguration;
    }

    /**
     * Returns the key variables for this dataset.
     * These variables are used when creating a new subject.
     */
    get keyVariables(): FhirAttribute[] {
        const primaryKey = "idcode,land"; // TODO: hardcoded now, change when primaryKey filled to following: this._scm.primaryKey;
        const keyVariableNames = primaryKey.split(",");
        return this._attributes.filter(attribute => keyVariableNames.includes(attribute.name)); // Hardcoded for now
    }

    get parentEntity(): FhirEntity | null {
        return this._parentEntity;
    }

    get layoutHideAddSubjectInSections(): string[] {
        return [];
    }

    private _visibilities?: DefaultVisibility[];

    private _parentEntity: FhirEntity | null = null;
    private _parentEntityList: FhirEntityList | null = null;

    private _sections: FhirEntity[] = [];
    private _sectionsLookup: Record<string, FhirEntity> = {};

    private _categories: FhirSectionCategory[] = [];

    private _attributes: FhirAttribute[] = [];
    private _attributesLookup: Record<string, FhirAttribute> | null = null;

    constructor(
        private _scm: ScmEntity,
        private _formConfiguration?: FormConfigurationEntity,
        { parentEntityList, visibilities }: FhirEntityConstructorOptions = {
            parentEntityList: null
        }
    ) {
        this._parentEntityList = parentEntityList;
        this._visibilities = visibilities;
        this._setCategories();
    }

    getVariable(name: string): FhirAttribute | undefined {
        return this._attributesLookup?.[name];
    }

    getSection(name: string): FhirEntity | null {
        return this._sectionsLookup[name] ?? null;
    }

    getPreviousSection(section: FhirEntity, subject?: FHIRSubject | null): FhirEntity | null {
        const index = this._sections.indexOf(section);
        if (index === -1) return null;

        const prevSection = this._sections[index - 1] ?? null;

        if (prevSection && subject && !prevSection.getIsVisible(subject)) {
            return this.getPreviousSection(prevSection, subject);
        }

        return prevSection;
    }

    getNextSection(section: FhirEntity, subject?: FHIRSubject | null): FhirEntity | null {
        const index = this._sections.indexOf(section);
        if (index === -1) return null;

        const nextSection = this._sections[index + 1] ?? null;

        if (nextSection && subject && !nextSection.getIsVisible(subject)) {
            return this.getNextSection(nextSection, subject);
        }

        return nextSection;
    }

    getLastSection(subject: FHIRSubject): FhirEntity | null {
        const lastSection = this._sections.at(-1);
        if (lastSection !== undefined && !lastSection.getIsVisible(subject)) {
            return this.getPreviousSection(lastSection, subject);
        }
        return lastSection ?? null;
    }

    getParentEntity(): FhirEntity | null {
        return null;
    }

    setParentEntity(entity: FhirEntity): void {
        this._parentEntity = entity;
    }

    createNewSection({ visibilities }: { visibilities?: DefaultVisibility[] }): FhirEntity {
        const keyVariables = this.keyVariables;
        let scm = {
            ...this._scm,
            childContract: {
                ...this._scm.childContract,
                attributes: this._scm.childContract.attributes.filter(attribute =>
                    keyVariables.find(variable => variable.name === attribute.name)
                )
            }
        };
        const section = new FhirEntity(
            scm,
            {
                type: "entity",
                contractId: "$new",
                attributes: keyVariables
                    .map(variable => variable.formConfiguration)
                    .filter(variable => !!variable)
            },
            {
                parentEntityList: null,
                visibilities
            }
        );
        section.setParentEntity(this);
        this._sections.push(section);
        return section;
    }

    initializeSections(entities: FhirEntity[]): void {
        this._setSections(entities);
    }

    getIsVisible(subject: FHIRSubject | null): boolean {
        for (const attribute of this._attributes) {
            const subjectVariable = subject?.variables.find(fv => fv.name === attribute.name);
            if (attribute.getIsVisible(subjectVariable)) return true;
        }

        return false;
    }

    getValidationMessages(subject: FHIRSubject): string[] {
        return this.attributes
            .filter(attribute => {
                const subjectVariable = subject.getVariable(attribute.name);
                return (
                    (attribute?.getIsVisible(subjectVariable) ?? false) &&
                    (!attribute?.getIsValid(subjectVariable) ?? false)
                );
            })
            .map(variable => {
                const subjectVariable = subject.getVariable(variable.name);
                return (variable?.getValidations(subjectVariable) ?? [])
                    .map(validationData => validationData.message)
                    .filter(message => message?.trim().length)
                    .join(", ");
            });
    }

    getInvalidUnskippableVariables(subject: FHIRSubject): FhirAttribute[] {
        return this._attributes.filter(attribute => {
            const subjectVariable = subject.getVariable(attribute.name);
            return attribute.isUnskippable && subjectVariable?.visible && !subjectVariable?.valid;
        });
    }

    private _setSections(entities: FhirEntity[]): void {
        if (entities.length === 0) {
            return;
        }

        if (this.name != "patient") {
            // hardcoded for now, no other way to do it
            this.setParentEntity(entities[0]);
            return;
        }
        for (const section of entities) {
            this._sections.push(section);
            this._sectionsLookup[section.name] = section;
        }
    }

    private _setCategories(): void {
        const categoryLookup: Record<string, FhirSectionCategory> = {};

        for (const index in this._scm.childContract.attributes) {
            const attributeFormConfiguration = this._formConfiguration?.attributes[index];
            const attribute = this._scm.childContract.attributes[index];
            const unselectableOptions = this._getUnselectableOptions(attribute.name);
            const modifiedAttribute = new FhirAttribute(
                attribute,
                this._scm.childContract.attachedExtension || [],
                attributeFormConfiguration,
                {
                    unselectableOptions
                }
            );
            if (!this._attributesLookup) {
                this._attributesLookup = {};
            }
            this._attributes.push(modifiedAttribute);
            this._attributesLookup[attribute.name] = modifiedAttribute;

            const { category, categoryLabel } = modifiedAttribute;

            let sectionCategory = categoryLookup[category];

            if (!sectionCategory) {
                sectionCategory = new FhirSectionCategory(category, categoryLabel);
                categoryLookup[category] = sectionCategory;
                this._categories.push(sectionCategory);
            }

            sectionCategory.addVariable(modifiedAttribute);
        }
    }

    private _getUnselectableOptions(attributeName: string): string[] | undefined {
        return this._visibilities
            ?.filter(visibility => visibility.name === attributeName)
            ?.flatMap(visibility => visibility.option)
            .map(option => option.value);
    }
}
