import { GET_SCM_RESPONSE_TESTDATA } from "../response/get-scm-response-testdata";
import { HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";

import { Observable, of } from "rxjs";

import {
    IHttpRequestObserveResponseOptions,
    ServerGatewayBase
} from "@logex/framework/lg-application";

import {
    CreateSubjectResponseData,
    DefaultValuesResponseData,
    DeleteSubjectResponseData,
    GetPermissionsResponseData,
    GetExternalOptionsetResponseData,
    GetStatementResponseData,
    GetSubjectResponseData,
    GetSubjectListResponseData,
    LockResponseData,
    UpdateExternalOptionsetResponseData,
    DeleteExternalOptionsetResponseData,
    GetScmResponseData,
    GET_FORM_CONFIGURATION_RESPONSE_TESTDATA
} from "../response";

import { SubjectListQuery } from "./fhir-middleware-api.types";
import { UpdateVariableResponseData } from "../response/update-variable-response-data";
import { FHIR_MIDDLEWARE_SERVICE_API_URL } from "./fhir";
import { FetchSubjectResponseData } from "../response/fetch-subject-response-data";
import { GetFormConfigurationResponseData } from "../response/get-form-configuration-response-data";

interface SettableHttpOptions {
    organizationId: number;
    contentType?: string;
    queryParams?: Record<string, any> | null;
    body?: any;
}

export interface UpdateVariablesRequestVariables {
    [VariableName: string]: string;
}

@Injectable({ providedIn: "root" })
export class FHIRMiddlewareApiService extends ServerGatewayBase {
    constructor(@Inject(FHIR_MIDDLEWARE_SERVICE_API_URL) baseUrl: string) {
        super();
        this._setBaseUrl(baseUrl);
    }

    getProjectPermissions$(
        organizationId: number,
        projectName: string
    ): Observable<HttpResponse<GetPermissionsResponseData>> {
        const httpOptions = this._getHttpOptions({ organizationId });
        return this._get<GetPermissionsResponseData>(`/${projectName}/permissions`, httpOptions);
    }

    getProjectStatement$(
        organizationId: number,
        projectName: string,
        language: string
    ): Observable<HttpResponse<GetStatementResponseData>> {
        const httpOptions = this._getHttpOptions({ organizationId, queryParams: { language } });
        return this._get<GetStatementResponseData>(`/${projectName}/statement`, httpOptions);
    }

    getFormConfiguration$(
        organizationId: number,
        projectName: string
    ): Observable<HttpResponse<GetFormConfigurationResponseData>> {
        const httpOptions = this._getHttpOptions({ organizationId });
        // temporary before FC deployed
        return of(
            new HttpResponse<GetFormConfigurationResponseData>({
                body: structuredClone(GET_FORM_CONFIGURATION_RESPONSE_TESTDATA)
            })
        );
        return this._get<GetFormConfigurationResponseData>(
            `/${projectName}/form-configuration`,
            httpOptions
        );
    }

    getScm$(
        organizationId: number,
        projectName: string
    ): Observable<HttpResponse<GetScmResponseData>> {
        const httpOptions = this._getHttpOptions({ organizationId });
        // temporary before SC deployed
        return of(
            new HttpResponse<GetScmResponseData>({
                body: structuredClone(GET_SCM_RESPONSE_TESTDATA)
            })
        );
        return this._get<GetScmResponseData>(`/${projectName}/scm`, httpOptions);
    }

    getSubjectList$(
        organizationId: number,
        projectName: string,
        { pageNumber, pageSize, columns, sortBy, sortType, searchCriteria }: SubjectListQuery
    ): Observable<HttpResponse<GetSubjectListResponseData>> {
        const httpOptions = this._getHttpOptions({
            organizationId,
            queryParams: {
                pageNumber,
                pageSize,
                columns,
                sortBy,
                sortType,
                ...searchCriteria
            }
        });
        return this._get<GetSubjectListResponseData>(`/${projectName}/subjectlist`, httpOptions);
    }

    // TODO: Might actually end up with different types
    getPublishedList$(
        organizationId: number,
        projectName: string,
        { pageNumber, pageSize, columns, sortBy, sortType, searchCriteria }: SubjectListQuery
    ): Observable<HttpResponse<GetSubjectListResponseData>> {
        const httpOptions = this._getHttpOptions({
            organizationId,
            queryParams: {
                pageNumber,
                pageSize,
                columns,
                sortBy,
                sortType,
                ...searchCriteria
            }
        });
        return this._get<GetSubjectListResponseData>(`/${projectName}/publishedList`, httpOptions);
    }

    getSubjectDefaultValues$(
        organizationId: number,
        projectName: string,
        datasetApiPath: string,
        subjectApiPath: string
    ): Observable<HttpResponse<DefaultValuesResponseData>> {
        const httpOptions = this._getHttpOptions({ organizationId });
        return this._get<DefaultValuesResponseData>(
            `/${projectName}/${datasetApiPath}/${subjectApiPath}/default-values`,
            httpOptions
        );
    }

    getSubject$(
        organizationId: number,
        projectName: string,
        datasetName: string,
        subjectName: string
    ): Observable<HttpResponse<GetSubjectResponseData>> {
        const httpOptions = this._getHttpOptions({ organizationId });
        return this._get<GetSubjectResponseData>(
            `/${projectName}/${datasetName}/${subjectName}`,
            httpOptions
        );
    }

    createSubject$(
        organizationId: number,
        projectName: string,
        variablevalues: Record<string, string>
    ): Observable<HttpResponse<CreateSubjectResponseData>> {
        const httpOptions = this._getHttpOptions({
            organizationId,
            body: variablevalues,
            contentType: "application/json"
        });
        return this._post<CreateSubjectResponseData>(`/${projectName}/patient`, httpOptions);
    }

    fetchSubject$(
        organizationId: number,
        projectName: string,
        variablevalues: Record<string, string>
    ): Observable<HttpResponse<FetchSubjectResponseData>> {
        const httpOptions = this._getHttpOptions({
            organizationId,
            body: variablevalues,
            contentType: "application/json"
        });
        return this._post<FetchSubjectResponseData>(`/${projectName}/fetch`, httpOptions);
    }

    createSubjectChild$(
        organizationId: number,
        projectName: string,
        datasetApiPath: string,
        subjectApiPath: string,
        variablevalues: Record<string, string>,
        etag: string
    ): Observable<HttpResponse<CreateSubjectResponseData>> {
        const httpOptions = this._getHttpOptions({
            organizationId,
            body: {
                initialValues: variablevalues,
                etag
            },
            contentType: "application/json"
        });
        return this._post<CreateSubjectResponseData>(
            `/${projectName}/${datasetApiPath}/${subjectApiPath}/`,
            httpOptions
        );
    }

    copySubject$(
        organizationId: number,
        projectName: string,
        datasetApiPath: string,
        subjectApiPath: string
    ): Observable<HttpResponse<GetSubjectResponseData>> {
        const httpOptions = this._getHttpOptions({ organizationId });
        return this._post<GetSubjectResponseData>(
            `/${projectName}/${datasetApiPath}/${subjectApiPath}/copy`,
            httpOptions
        );
    }

    deleteSubject$(
        organizationId: number,
        projectName: string,
        datasetApiPath: string,
        subjectApiPath: string,
        { etag }: { etag: string }
    ): Observable<HttpResponse<DeleteSubjectResponseData>> {
        const httpOptions = this._getHttpOptions({
            organizationId,
            contentType: "application/json",
            body: etag
        });
        return this._delete<DeleteSubjectResponseData>(
            `/${projectName}/${datasetApiPath}/${subjectApiPath}/`,
            httpOptions
        );
    }

    updateVariable$(
        organizationId: number,
        projectName: string,
        datasetApiPath: string,
        subjectApiPath: string,
        variableName: string,
        variableValue: string,
        { etag }: { etag: string }
    ): Observable<HttpResponse<UpdateVariableResponseData>> {
        const httpOptions = this._getHttpOptions({
            organizationId,
            contentType: "application/json-patch+json",
            body: { value: variableValue, etag }
        });

        return this._put<UpdateVariableResponseData>(
            `/${projectName}/${datasetApiPath}/${subjectApiPath}/${variableName}`,
            httpOptions
        );
    }

    updateVariables$(
        organizationId: number,
        projectName: string,
        datasetApiPath: string,
        subjectApiPath: string,
        variables: UpdateVariablesRequestVariables,
        { etag }: { etag: string }
    ): Observable<HttpResponse<UpdateVariableResponseData>> {
        const httpOptions = this._getHttpOptions({
            organizationId,
            contentType: "application/json-patch+json",
            body: { variables, etag }
        });

        return this._put<UpdateVariableResponseData>(
            `/${projectName}/${datasetApiPath}/${subjectApiPath}`,
            httpOptions
        );
    }

    lock$(
        organizationId: number,
        projectName: string,
        datasetName: string,
        subjectName: string,
        etag: string
    ): Observable<HttpResponse<LockResponseData>> {
        const httpOptions = this._getHttpOptions({
            organizationId,
            contentType: "application/json-patch+json",
            body: etag
        });
        return this._post<LockResponseData>(
            `/${projectName}/${datasetName}/${subjectName}/lock`,
            httpOptions
        );
    }

    unlock$(
        organizationId: number,
        projectName: string,
        datasetName: string,
        subjectName: string,
        etag: string
    ): Observable<HttpResponse<void>> {
        const httpOptions = this._getHttpOptions({
            organizationId,
            body: etag,
            contentType: "application/json-patch+json"
        });

        return this._post<void>(
            `/${projectName}/${datasetName}/${subjectName}/unlock`,
            httpOptions
        );
    }

    getExternalOptionset$(
        organizationId: number,
        projectName: string,
        datasetApiPath: string,
        subjectApiPath: string,
        variableName: string
    ): Observable<HttpResponse<GetExternalOptionsetResponseData>> {
        const httpOptions = this._getHttpOptions({
            organizationId,
            queryParams: { variable: variableName }
        });

        return this._get<GetExternalOptionsetResponseData>(
            `/${projectName}/${datasetApiPath}/${subjectApiPath}/externalOptionset`,
            httpOptions
        );
    }

    updateExternalOptionset$(
        organizationId: number,
        projectName: string,
        datasetApiPath: string,
        subjectApiPath: string,
        variableName: string,
        { rowId, etag }: { rowId: string; etag: string }
    ): Observable<HttpResponse<UpdateExternalOptionsetResponseData>> {
        const httpOptions = this._getHttpOptions({
            organizationId,
            contentType: "application/json-patch+json",
            body: { variable: variableName, dataRowId: rowId, etag }
        });

        return this._put<UpdateExternalOptionsetResponseData>(
            `/${projectName}/${datasetApiPath}/${subjectApiPath}/externalOptionset`,
            httpOptions
        );
    }

    deleteExternalOptionset$(
        organizationId: number,
        projectName: string,
        datasetApiPath: string,
        subjectApiPath: string,
        { variableName, etag }: { variableName: string; etag: string }
    ): Observable<HttpResponse<DeleteExternalOptionsetResponseData>> {
        const httpOptions = this._getHttpOptions({
            organizationId,
            contentType: "application/json-patch+json",
            body: { variable: variableName, etag }
        });

        return this._delete<DeleteExternalOptionsetResponseData>(
            `/${projectName}/${datasetApiPath}/${subjectApiPath}/externalOptionset`,
            httpOptions
        );
    }

    private _getHttpOptions({
        organizationId,
        contentType,
        queryParams = null,
        body = ""
    }: SettableHttpOptions): IHttpRequestObserveResponseOptions {
        const httpOptions: IHttpRequestObserveResponseOptions = {
            observe: "response"
        };

        httpOptions.headers = {};
        httpOptions.headers["x-organization-id"] = String(organizationId);

        if (contentType) {
            httpOptions.headers["content-type"] = contentType;
        }

        if (queryParams) {
            httpOptions.params = {};
            for (const name of Object.keys(queryParams)) {
                if (typeof queryParams[name] !== "undefined") {
                    if (Array.isArray(queryParams[name])) {
                        httpOptions.params[name] = queryParams[name];
                    } else {
                        httpOptions.params[name] = String(queryParams[name]);
                    }
                }
            }
        }

        if (body) {
            httpOptions.body = JSON.stringify(body);
        }

        return httpOptions;
    }
}
