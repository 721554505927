import {
    DataEntryUIControlType,
    FormConfiguration,
    FormConfigurationCreateFromExistingPickerType,
    FormConfigurationEntityHelperType,
    FormConfigurationOptionsDirection,
    GetFormConfigurationResponseData
} from "./get-form-configuration-response-data";

export const DHBA_FORM_CONFIGURATION_TESTDATA: FormConfiguration = {
    uuid: "generated UUID",
    type: "dataset",
    businessId: "DHBA_test_patient_form",
    name: "dhba-2024",
    author: "Victoria Gardukevich",
    maintainer: "Victoria Gardukevich",
    status: "published",
    version: "1.0.0",
    contractId: "strong contract UUID",
    childContract: [
        {
            type: "entityListGroup",
            contractId: "group strong contract UUID",
            childContract: [
                {
                    type: "entityList",
                    contractId: "list strong contract UUID",
                    childContract: [
                        {
                            type: "entity",
                            label: "Patient",
                            contractId: "entity strong contract UUID",
                            attributes: [
                                {
                                    name: "id",
                                    label: "Zorginstelling",
                                    inputControl: DataEntryUIControlType.Text
                                },
                                {
                                    name: "upn",
                                    label: "Uniek Patiëntnummer",
                                    inputControl: DataEntryUIControlType.Text
                                },
                                {
                                    name: "land",
                                    label: "Land Burger Service Nummer",
                                    inputControl: DataEntryUIControlType.Radio,
                                    optionsDirection: FormConfigurationOptionsDirection.Vertical
                                },
                                {
                                    name: "idcode",
                                    label: "Burger Service Nummer",
                                    inputControl: DataEntryUIControlType.Text
                                },
                                {
                                    name: "tussen",
                                    label: "Voorvoegsels",
                                    inputControl: DataEntryUIControlType.Text
                                },
                                {
                                    name: "naam",
                                    label: "Geslachtsnaam",
                                    inputControl: DataEntryUIControlType.Text
                                },
                                {
                                    name: "gebdat",
                                    label: "Geboortedatum",
                                    inputControl: DataEntryUIControlType.Date
                                },
                                {
                                    name: "geslacht",
                                    label: "Geslacht",
                                    inputControl: DataEntryUIControlType.Radio,
                                    optionsDirection: FormConfigurationOptionsDirection.Vertical
                                },
                                {
                                    name: "land-woon",
                                    label: "Land (Woonadres)",
                                    inputControl: DataEntryUIControlType.Select,
                                    defaultValue: "nl"
                                },
                                {
                                    name: "pcode",
                                    label: "Postcode",
                                    inputControl: DataEntryUIControlType.Text
                                },
                                {
                                    name: "datovl",
                                    label: "Datum overlijden (indien van toepassing)",
                                    inputControl: DataEntryUIControlType.Date
                                }
                            ]
                        }
                    ]
                },
                {
                    type: "entityList",
                    contractId: "list strong contract UUID",
                    settings: {
                        createFromExisting: {
                            pickerType: FormConfigurationCreateFromExistingPickerType.LatestDate
                        }
                    },
                    childContract: [
                        {
                            type: "entity",
                            label: "Commorbiditeiten",
                            contractId: "entity strong contract UUID",
                            helpers: [
                                {
                                    name: "test-setter",
                                    type: FormConfigurationEntityHelperType.Setter,
                                    label: ".SetterLabel",
                                    attributesToSet: ["commyo", "comhartfaal"],
                                    options: [
                                        {
                                            value: "true",
                                            label: "Yes to all"
                                        },
                                        {
                                            value: "false",
                                            label: "No to all"
                                        }
                                    ],
                                    position: {
                                        type: "after",
                                        referenceItemName: "datcom"
                                    }
                                }
                            ],
                            attributes: [
                                {
                                    name: "datcom",
                                    label: "Datum comorbiditeiten",
                                    inputControl: DataEntryUIControlType.Date
                                },
                                {
                                    name: "commyo",
                                    label: "Myocardinfarct",
                                    inputControl: DataEntryUIControlType.Radio,
                                    optionsDirection: FormConfigurationOptionsDirection.Horizontal
                                },
                                {
                                    name: "comhartfaal",
                                    label: "Congestief hartfalen",
                                    inputControl: DataEntryUIControlType.Radio,
                                    optionsDirection: FormConfigurationOptionsDirection.Horizontal
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]
};

export const DCRA_2024_FORM_CONFIGURATION_TESTDATA: FormConfiguration = {
    uuid: "generated UUID",
    type: "dataset",
    businessId: "DHBA_test_patient_form",
    name: "dcra-2024",
    author: "Victoria Gardukevich",
    maintainer: "Victoria Gardukevich",
    status: "published",
    version: "1.0.0",
    contractId: "strong contract UUID",
    childContract: [
        {
            type: "entityListGroup",
            contractId: "group strong contract UUID",
            childContract: [
                {
                    type: "entityList",
                    contractId: "list strong contract UUID",
                    childContract: [
                        {
                            type: "entity",
                            label: "Patient",
                            contractId: "entity strong contract UUID",
                            attributes: [
                                {
                                    name: "id",
                                    label: "Zorginstelling",
                                    inputControl: DataEntryUIControlType.Text
                                },
                                {
                                    name: "upn",
                                    label: "Uniek Patiëntnummer",
                                    inputControl: DataEntryUIControlType.Text
                                },
                                {
                                    name: "land",
                                    label: "Land Burger Service Nummer",
                                    inputControl: DataEntryUIControlType.Radio,
                                    optionsDirection: FormConfigurationOptionsDirection.Vertical
                                },
                                {
                                    name: "idcode",
                                    label: "Burger Service Nummer",
                                    inputControl: DataEntryUIControlType.Text
                                },
                                {
                                    name: "tussen",
                                    label: "Voorvoegsels",
                                    inputControl: DataEntryUIControlType.Text
                                },
                                {
                                    name: "naam",
                                    label: "Geslachtsnaam",
                                    inputControl: DataEntryUIControlType.Text
                                },
                                {
                                    name: "gebdat",
                                    label: "Geboortedatum",
                                    inputControl: DataEntryUIControlType.Date
                                },
                                {
                                    name: "geslacht",
                                    label: "Geslacht",
                                    inputControl: DataEntryUIControlType.Radio,
                                    optionsDirection: FormConfigurationOptionsDirection.Vertical
                                },
                                {
                                    name: "land-woon",
                                    label: "Land (Woonadres)",
                                    inputControl: DataEntryUIControlType.Select,
                                    defaultValue: "nl"
                                },
                                {
                                    name: "pcode",
                                    label: "Postcode",
                                    inputControl: DataEntryUIControlType.Text
                                },
                                {
                                    name: "datovl",
                                    label: "Datum overlijden (indien van toepassing)",
                                    inputControl: DataEntryUIControlType.Date
                                }
                            ]
                        }
                    ]
                },
                {
                    type: "entityList",
                    contractId: "list strong contract UUID",
                    settings: {
                        createFromExisting: {
                            pickerType: FormConfigurationCreateFromExistingPickerType.LatestDate
                        }
                    },
                    childContract: [
                        {
                            type: "entity",
                            label: "Commorbiditeiten",
                            contractId: "entity strong contract UUID",
                            helpers: [
                                {
                                    name: "test-setter",
                                    type: FormConfigurationEntityHelperType.Setter,
                                    label: ".SetterLabel",
                                    attributesToSet: ["commyo", "comhartfaal"],
                                    options: [
                                        {
                                            value: "true",
                                            label: "Yes to all"
                                        },
                                        {
                                            value: "false",
                                            label: "No to all"
                                        }
                                    ],
                                    position: {
                                        type: "after",
                                        referenceItemName: "datcom"
                                    }
                                }
                            ],
                            attributes: [
                                {
                                    name: "datcom",
                                    label: "Datum comorbiditeiten",
                                    inputControl: DataEntryUIControlType.Date
                                },
                                {
                                    name: "commyo",
                                    label: "Myocardinfarct",
                                    inputControl: DataEntryUIControlType.Radio,
                                    optionsDirection: FormConfigurationOptionsDirection.Horizontal
                                },
                                {
                                    name: "comhartfaal",
                                    label: "Congestief hartfalen",
                                    inputControl: DataEntryUIControlType.Radio,
                                    optionsDirection: FormConfigurationOptionsDirection.Horizontal
                                },
                                {
                                    name: "patient_upn",
                                    label: "Uniek Patiëntnummer",
                                    inputControl: DataEntryUIControlType.Text,
                                    include: false
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]
};

export const GET_FORM_CONFIGURATION_RESPONSE_TESTDATA: GetFormConfigurationResponseData = [
    DCRA_2024_FORM_CONFIGURATION_TESTDATA
];
