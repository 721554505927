import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { LOCALE_ID, Injectable, inject } from "@angular/core";
import { distinctUntilChanged } from "rxjs";

import { LgLocalizationSettings } from "@logex/framework/lg-localization";
import { LocalizationJson } from "@logex/framework/lg-localization/translate/lg-localization.types";

import { AppProjectsService } from "../app-projects.service";

@Injectable()
export class AppLocalizationSettings implements LgLocalizationSettings {
    private _locale = inject(LOCALE_ID);

    readonly bootstrapDone = Promise.resolve();

    readonly languages: Record<string, LocalizationJson[]> = {};

    private _preferredLanguage;

    constructor() {
        const preferredLanguage = localStorage.getItem("preferredLanguage");
        if (preferredLanguage != null && this.availableLanguages.includes(preferredLanguage)) {
            this._preferredLanguage = preferredLanguage;
        } else {
            this._preferredLanguage = this._locale;
        }
    }

    get fallbackLanguage(): string {
        return "en";
    }

    get preferredLanguage(): string {
        return this._preferredLanguage;
    }

    get availableLanguages(): string[] {
        return ["en-GB", "nl-NL"];
    }

    get locale(): string {
        return this.preferredLanguage;
    }

    get currency(): string {
        return "EUR";
    }

    switchCurrentLanguage(lang: string): void {
        localStorage.setItem("preferredLanguage", lang);
        window.location.reload();
    }

    addStrings(lang: string, strings: LocalizationJson): void {
        this.languages[lang] = [...(this.languages[lang] ?? []), strings];
    }

    setPreferredLanguage(lang: string): void {
        this._preferredLanguage = lang;
    }

    initAutomaticSwitch(): void {
        const projectsService = inject(AppProjectsService);

        projectsService.selectedProject$
            .pipe(distinctUntilChanged(), takeUntilDestroyed())
            .subscribe(project => {
                if (project?.defaultLanguage == null || project.defaultLanguage.length === 0) {
                    return;
                }

                const parsedLang = this.availableLanguages.find(lang =>
                    lang.includes(project.defaultLanguage!)
                );
                if (parsedLang !== undefined && parsedLang !== this.preferredLanguage) {
                    this.switchCurrentLanguage(parsedLang);
                }
            });
    }
}
