import {
    FormConfigurationCreateFromExistingSettings,
    FormConfigurationEntity,
    FormConfigurationEntityHelper,
    FormConfigurationEntityLayout
} from "@fhir-api";
import { FhirAttribute } from "./attribute";
import { FhirEntityList } from "./entity-list";
import { ScmEntity } from "@logex/expression-validator";

export class FhirEntity {
    get name(): string {
        return this._parentEntityList.scm.businessAlias ?? this._scm.content.name;
    }

    get label(): string {
        return this._formConfiguration?.label ?? this.name;
    }

    get layout(): FormConfigurationEntityLayout | null {
        return null;
    }

    get wordLabel(): string {
        return this.label.toLocaleLowerCase();
    }

    get startLabel(): string {
        if (this.wordLabel.length === 0) {
            return this.wordLabel;
        }
        const word = this.wordLabel;
        return word[0].toLocaleUpperCase() + word.substring(1);
    }

    get attributes(): FhirAttribute[] {
        return this._attributes;
    }

    get scm(): ScmEntity {
        return this._scm;
    }

    get preferredOrder(): number {
        return this.parentEntityList.scm.preferredOrder;
    }

    get formConfiguration(): FormConfigurationEntity | undefined {
        return this._formConfiguration;
    }

    get parentEntityList(): FhirEntityList {
        return this._parentEntityList;
    }

    get helpers(): FormConfigurationEntityHelper[] | undefined {
        return this._formConfiguration?.helpers;
    }

    get createFromCopy(): FormConfigurationCreateFromExistingSettings | null {
        return this._parentEntityList?.formConfiguration?.settings?.createFromExisting ?? null;
    }
    /**
     * Returns the key variables for this dataset.
     * These variables are used when creating a new subject.
     */
    get keyVariables(): FhirAttribute[] {
        const primaryKey = this._scm.primaryKey;
        const keyVariableNames = primaryKey.split(",");
        return this._attributes.filter(attribute => keyVariableNames.includes(attribute.name)); // Hardcoded for now
    }

    private _attributes: FhirAttribute[] = [];
    private _attributesLookup: Record<string, FhirAttribute> | null = null;

    constructor(
        private _parentEntityList: FhirEntityList,
        private _scm: ScmEntity,
        private _formConfiguration?: FormConfigurationEntity
    ) {
        this._setAttributes();
    }

    getVariable(name: string): FhirAttribute | null {
        return this._attributesLookup?.[name] ?? null;
    }

    private _setAttributes(): void {
        for (const scmAttribute of this._scm.content.attributes) {
            const fcAttribute = this._formConfiguration?.attributes.find(
                ({ name }) => name === scmAttribute.name
            );
            const modifiedAttribute = new FhirAttribute(
                this,
                scmAttribute,
                this._scm.content.attachedExtension || [],
                fcAttribute
            );
            if (!this._attributesLookup) {
                this._attributesLookup = {};
            }
            this._attributes.push(modifiedAttribute);
            this._attributesLookup[scmAttribute.name] = modifiedAttribute;
        }
    }
}
