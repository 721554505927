import { FormConfigurationEntityList, ScmEntityList } from "@fhir-api";
import { FhirEntity } from "./fhir-entity";
import { FhirEntityListGroup } from "./fhir-entity-list-group";

export class FhirEntityList {
    get scm(): ScmEntityList {
        return this._scm;
    }

    get formConfiguration(): FormConfigurationEntityList | undefined {
        return this._formConfiguration;
    }

    get entity(): FhirEntity {
        return this._entity;
    }

    get parentEntityListGroup(): FhirEntityListGroup | null {
        return this._parentEntityListGroup;
    }

    private _parentEntityListGroup: FhirEntityListGroup | null = null;
    private _entity: FhirEntity;

    constructor(
        private _scm: ScmEntityList,
        { parentEntityListGroup }: { parentEntityListGroup: FhirEntityListGroup },
        private _formConfiguration?: FormConfigurationEntityList
    ) {
        this._parentEntityListGroup = parentEntityListGroup;
        this._entity = new FhirEntity(
            _scm.childContract.childContract,
            this._formConfiguration?.childContract?.[0],
            {
                parentEntityList: this
            }
        );
    }

    public initializeSections(entities: FhirEntity[]): void {
        this._entity.initializeSections(entities);
    }
}
