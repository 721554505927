import { HttpClient, HttpResponse } from "@angular/common/http";
import { inject, Inject, Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { LgConsole } from "@logex/framework/core";
import {
    BrowserCacheProvider,
    IHttpRequestObserveResponseOptions,
    ServerGatewayBase
} from "@logex/framework/lg-application";

import { GetProjectsResponseData } from "../response";
import { FHIR_METADATA_SERVICE_API_URL } from "./fhir";

@Injectable({ providedIn: "root" })
export class FHIRMetadataApiService extends ServerGatewayBase {
    baseUrl: string = inject<string>(FHIR_METADATA_SERVICE_API_URL);

    constructor() {
        super();
        this._setBaseUrl(this.baseUrl);
    }

    getProjectAssetUrl(projectName: string): string {
        return `${this._baseUrl ?? ""}/projects/${projectName}/assets`;
    }

    getProjects$(
        organizationId: number,
        { filtered = true }: { filtered?: boolean } = {}
    ): Observable<HttpResponse<GetProjectsResponseData>> {
        const httpOptions = this._getHttpOptions(organizationId, { filtered });
        return this._get<GetProjectsResponseData>("/projects", httpOptions);
    }

    private _getHttpOptions(
        organizationId: number,
        queryParams?: Record<string, any>
    ): IHttpRequestObserveResponseOptions {
        const params: {
            [param: string]: any;
        } = {};

        if (queryParams) {
            for (const name of Object.keys(queryParams)) {
                if (typeof queryParams[name] !== "undefined") {
                    params[name] = String(queryParams[name]);
                }
            }
        }

        return {
            params,
            headers: {
                ["x-organization-id"]: organizationId.toString()
            },
            observe: "response"
        };
    }
}
